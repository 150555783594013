import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { APP_CONFIG } from '../../config/config.token';
import { AppConfig } from '../../config/app.config';
import { AuthService } from '../../../vortex/services/auth.service';
import { RegisterModel } from '../../model/RegisterModel';
import { passwordMatchValidator } from '../../validators/passwordMatchValidator';
import { GlobalAsyncValidatorService } from '../../services/data/UserInviteService/global-async-validator-service';


@Component({
  selector: 'app-register',
  standalone: true,
  imports: [InputTextModule, RouterLink, CommonModule, ReactiveFormsModule, ButtonModule, CardModule, ToastModule],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  //registerForm: FormGroup;
  inviteCode: string | null = null;

  registerForm: FormGroup = this.fb.group({
    firstName: ['', [Validators.required,
                      Validators.pattern(/^[a-zA-Z ]*$/)]],
    lastName: ['', [Validators.required,
                      Validators.pattern(/^[a-zA-Z ]*$/)]],
     email: ['', [Validators.required, Validators.email], [this.validatorService.validateUserInvitationCode(this.activateRoute.snapshot.queryParamMap.get('code'))]],
     password: ['', Validators.required],
     confirmPassword: ['', Validators.required]
   }, {
     validators: passwordMatchValidator
   });
  constructor(
    private fb: FormBuilder,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private destroyRef: DestroyRef,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private validatorService: GlobalAsyncValidatorService
  ) {

   
  }

  ngOnInit() {
    this.inviteCode = this.activateRoute.snapshot.queryParamMap.get('code');
    if (!this.inviteCode) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invitation code is missing!' });
    }
  }

  get firstName() {
    return this.registerForm.controls['firstName'];
  }

  get lastName() {
    return this.registerForm.controls['lastName'];
  }

  get email() {
    return this.registerForm.controls['email'];
  }

  get password() {
    return this.registerForm.controls['password'];
  }

  get confirmPassword() {
    return this.registerForm.controls['confirmPassword'];
  }

  submitDetails() {
    const postData = { ...this.registerForm.value };
    delete postData.confirmPassword;
    postData.inviteCode = this.activateRoute.snapshot.queryParamMap.get('code');
    const subscription = this.authService.register(postData as RegisterModel).subscribe({
      next: (response: any) => {
        console.debug(response);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Register successfully' });
        this.router.navigate(['login'])
      },
        error: (error: any) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
        }
    });

    // destroy all subscriptions to prevent memory leaks.
    this.destroyRef.onDestroy(() => subscription.unsubscribe());
  }
}
