import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { Observable } from 'rxjs/internal/Observable';
import Campaign from '../../model/Campaign';
import { CommonModule, NgFor, NgIf, DOCUMENT } from '@angular/common';
import { DomHandler } from 'primeng/dom';
import { CardModule } from 'primeng/card';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CheckboxModule } from 'primeng/checkbox';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MainModel } from '../../model/MainModel';
import { cleanSVGForOutput } from '../../util/util';
import { ServerClientService } from '../../services/data/CampaignService/server-client.service';
import {FormControl} from '@angular/forms';

import {ReactiveFormsModule} from '@angular/forms';
import { GenericTextInputComponent } from "../generic/generic-text-input/generic-text-input.component";
import { RecentCampaigns } from "../recent-campaigns/recent-campaigns.component";

@Component({
  selector: 'rc-campaign-switcher',
  standalone: true,
  imports: [
    SidebarModule,
    CardModule,
    ToggleButtonModule,
    CheckboxModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    CommonModule,
    ReactiveFormsModule,
    NgIf,
    NgFor,
    GenericTextInputComponent,
    RecentCampaigns,
],
  templateUrl: './campaign-switcher.component.html',
  styleUrl: './campaign-switcher.component.scss'
})

export class CampaignSwitcherComponent implements OnInit {
  @Input({ required: true }) isDockable:boolean = true;
  isSidebarVisible:boolean = false;
  selectedCampaign:Campaign|null = null;
  checked: boolean = false;
  filterValue:FormControl = new FormControl('', { updateOn: 'change' });
  viewableCampaigns:Array<any> = [];

  filterResults:Function = () => this.model.campaigns.filter(
    i => (this.filterValue.value === null || 
      this.filterValue.value.replaceAll(" ", "") === "") ||
      i.name.toLowerCase().indexOf(this.filterValue.value?.toLowerCase()) >= 0
  )

  toggle:Function = (event:Event)=> {
    console.debug("toggle");
    this.isSidebarVisible = !this.isSidebarVisible
  }

  isSelectedCampaign:Function = (camp:any) => {
    if(!this.model.selectedCampaign)return false;
    return camp.label === this.model.selectedCampaign?.name;
  }

  getClass:Function = (obj:Campaign) => {
    this.selectedCampaign = this.model.selectedCampaign();
    let style = "";
    if(obj.isActive === false){
      style += " inactive"
    } 
    if(this.selectedCampaign && (obj.campaignId === this.selectedCampaign.campaignId))
    { 
      style += " selected"; 
    }
    return style;
  }

  selectCampaign:Function =(obj:Campaign) => {
    if(obj.isActive){
      this.model.selectedCampaign.set(obj);
      this.toggle(null);
      this.showActions();
    }
    //(sCamp);
  }
  
  constructor(
    @Inject(DOCUMENT) private document:Document,
    protected model:MainModel, 
    private service:ServerClientService
  ){
    this.viewableCampaigns = this.model.campaigns;
    this.model.navigation.document = document;
  }

  ngOnInit(): void {
    this.viewableCampaigns = this.model.campaigns;
  }

  hideActions(){
    DomHandler.addClass(this.document.body, 'actions-hidden');
  }

  showActions(){
    DomHandler.removeClass(this.document.body, 'actions-hidden');
  }

  // hide Actions if campaign selection is loaded 
  // ngOnInit() { 
  //   console.log("campaign selection initialized");
  //   this.hideActions();
  // }

  // ngOnDestroy() { 
  //   this.hideActions();
  // }

}
