import { Component, DestroyRef } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { AuthService } from '../../../vortex/services/auth.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [InputTextModule, CommonModule, ReactiveFormsModule, ButtonModule, CardModule, ToastModule, RouterLink],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required]
  })

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private msgService: MessageService,
    private destroyRef: DestroyRef
  ) { }

  get email() {
    return this.loginForm.controls['email'];
  }
  get password() { return this.loginForm.controls['password']; }

  loginUser() {
    const { email, password } = this.loginForm.value;
   
    const subscription = this.authService.login({ email: email!, password: password! }).subscribe({
      next: (data: any) => {
        this.msgService.add({ severity: 'success', summary: 'Success', detail: 'You have successfully logged in.' });
        setTimeout(() => {
          this.router.navigate(['campaign-selection'])
        }, 1000);
      },
      error: (error: any) => {
        this.msgService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred while trying to log in.' });
      }
    });

    this.destroyRef.onDestroy(() => subscription.unsubscribe());
  }
}
