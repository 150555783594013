import { Component, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { UserPermissionsService } from '../../services/permissions/UserPermissionsService';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';


/**
 * This component can be inherited by a page or component. The mina requirement for the extending component is to:
 * 1. Extend this class.
 * 2. Set the local feature id and/or a page id.
 * 3. Make sure a dom element that encompases the area requesting access has one of theses classes on it: .feature-container, .page-container, #paper
 * NOTE: #paper is the main content area in the web UI - so if you dont provide one of the other classes most likely your 
 * permissions error will stretch to the height and width of the #paper area of the UI.
 */
@Component({
  selector: 'app-permissions-checking-component',
  standalone: true,
  imports: [],
  templateUrl: './permissions-checking-component.component.html',
  styleUrl: './permissions-checking-component.component.scss'
})
export class PermissionsCheckingComponent implements AfterViewInit, OnInit {

  featureId:number = -1;
  pageId:number = -1;
  localPermission:number = -1;
  
  
  /**
   * 
   * @param elementRef 
   * @param permissionsAPI 
   */
  constructor(
    public elementRef_IN:ElementRef, 
    private permissionsAPI_IN:UserPermissionsService,
    private router_IN:Router
  ){
    this.checkPermisisons();
    
  }


  checkPermisisons(){
    const selector:string = "no-access";
    const parent = this.elementRef_IN.nativeElement;
    const existingModal = parent.querySelector(`.${selector}`);
    if(existingModal?.length)existingModal.forEach( (item:any) => parent?.removeChild(item ) );
    if(existingModal?.innerHTML)parent.removeChild(existingModal);
    this.localPermission = this.permissionsAPI_IN.getPermission(this.featureId, this.pageId);

    //console.debug("localPermission",this.localPermission)

    if( this.localPermission <= 0 ){
      try {
        const elementToAdd = document.createElement("div");
        elementToAdd.setAttribute("class", selector);
        elementToAdd.innerHTML = `<h2 class="warning">You do not have permissions for this area</h2>`;
        parent.appendChild(elementToAdd);
        //this.elementRef.nativeElement.parentElement.removeChild(this.elementRef.nativeElement);
      } catch(error){
        console.debug(error, "PERMISISON", this.featureId, this.pageId, this.localPermission);
      } 
    }
  }

  ngOnInit(): void {
    this.router_IN.events
    .pipe(filter(event => event instanceof NavigationEnd))  
    .subscribe((event:any) => {
      this.checkPermisisons();
    });
  }
  
  ngOnDestroy(): void {
  }
  

  ngOnChanges(): void {
    this.checkPermisisons();
  }
  
  ngDoCheck(): void {
    this.checkPermisisons();
  }
  
  ngAfterContentInit(): void {
    this.checkPermisisons();
  }
  

  ngAfterViewChecked(): void {
    this.checkPermisisons();
  }

  ngAfterViewInit(): void {
    this.checkPermisisons();
  }


  /**
   * 
   */
  afterNextRender(): void {
    this.checkPermisisons();
  }

}
