import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SecurityModel, Permission } from '../../model/SecurityModel';


/**
 * 
 */
@Injectable({
    providedIn: 'root'
})
export class UserPermissionsService {

    securityModel:SecurityModel = new SecurityModel();
    
    getFeatureAccess(fId:number):Permission{
        if(this.securityModel.isAdmin === true)return Permission.Write;
        const v:Permission|undefined = this.securityModel.features.get(fId);
        if(v === undefined)return Permission.None;
        return v;
    }

    getPageAccess(pId:number):Permission{
        if(this.securityModel.isAdmin === true)return Permission.Write;
        const v:Permission|undefined = this.securityModel.pages.get(pId);
        if(v === undefined)return Permission.None;
        return v;
    }
    
    /**
     * This funciton is for the navigation where all sub features and page ids are copied up to the top level 
     * containing nav item - e.g. Donors, Donations
     * @param featureIds 
     * @param pageIds 
     */
    getAccessFromFlattenedArray( featureIds:Array<number>|undefined, pageIds:Array<number>|undefined ):Permission{
        if(this.securityModel.isAdmin === true)return Permission.Write;
        let fPerm:Permission = Permission.None;
        let pPerm:Permission = Permission.None;
       if(featureIds?.length){
        featureIds.forEach(
            i => {
                const p:Permission = this.getFeatureAccess(i);
                if(p !== Permission.None)fPerm = p;
            }
        )
       }
       if(pageIds?.length){
        pageIds.forEach(
            i => {
                const p:Permission = this.getPageAccess(i);
                if(p !== Permission.None)pPerm = p;
            }
        )
       }
       //console.debug(featureIds, pageIds, fPerm, pPerm)
       return fPerm >= pPerm ? fPerm : pPerm ;
    }
    
    getPageAndFeatureAccess(key:string):Permission{
        if(this.securityModel.isAdmin === true)return Permission.Write;
        const v:Permission|undefined = this.securityModel.featureAndPage.get(key);
        if(v === undefined)return Permission.None;
        return v;
    }

    clear(){
        this.securityModel.isAdmin = false;
        this.securityModel.pages.clear();
        this.securityModel.featureAndPage.clear();
        this.securityModel.features.clear();
    }

    /**
     * It will set permissions on the existing app array.
     * @param itemOrArray 
     * @param parent 
     */
    ingestPermissionData(itemOrArray:any, parent:any|undefined = undefined) {
        if(parent === undefined)this.clear();
        // is it the top level structure???
        if(itemOrArray.campaignId !== undefined){
            this.securityModel.isAdmin = itemOrArray.isAdmin;
        }
        const { id, access } = itemOrArray;
        // feature level...
        if(id !== undefined && access !== undefined){
            this.securityModel.features.set(id, access);
        }

        if(parent !== undefined && parent.access){
            // we are at page level and the feature is the parent...
            this.securityModel.pages.set(id, parent.access);
            this.securityModel.featureAndPage.set(`${parent.id}:${itemOrArray.id}`, itemOrArray.access || parent.access || Permission.None);
        }

        if(itemOrArray.length || itemOrArray.campaignProducts || itemOrArray.features || itemOrArray.pages){
            (itemOrArray.campaignProducts || itemOrArray.features || itemOrArray.pages || itemOrArray)?.forEach(
                (element:any) => {
                    this.ingestPermissionData(element,itemOrArray);
                }
            );
        }
    }


    /**
     * This is the default function that should be called by external objects. 
     * It will do internal checking, etc... 
     * If a feature or page id is not a plausible value it will check by page or feature as opposed to both.
     * @param featureId 
     * @param pageId 
     * @returns 
     */
    getPermission(featureId:number = -1, pageId:number = -1 ):Permission {
        const key:string = `${featureId}:${pageId}`;
        let p:Permission = Permission.None;
        // console.debug(this.securityModel);
        if(this.securityModel.isAdmin === true)return Permission.Write;
        if(featureId >= 0 && pageId >= 0){
            p = this.getPageAndFeatureAccess(key);
            //console.debug("BOTH", key, featureId, pageId, p)
        } else if(featureId >= 0 && pageId === -1){
            p = this.getFeatureAccess(featureId);
            //console.debug("FEATURE", key, featureId, pageId, p)
        } else if(pageId >= 0 && featureId === -1){
            p = this.getPageAccess(pageId);
            //console.debug("PAGE", key, featureId, pageId, p)
        }
        return p;
    }
}   