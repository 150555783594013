<div class="card">
  <p-toast />
  <p-confirmDialog />
  <p-stepper orientation="vertical"
             (activeStepChange)="stepChange($event)"
             [linear]="true"
             #userInviteStepper>
    <p-stepperPanel header="User Details">
      <ng-template pTemplate="content" #userInputPanel let-nextCallback="userPanelNextCallback" let-index="index">
        <form [formGroup]="personalInfoForm">
          <div class="formgrid grid border-2 border-solid surface-border border-round surface-ground">
            <div class="field col-12 md:col-6 pt-2">
              <label for="firstname">First Name</label>
              <input id="firstname"
                     type="text"
                     class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                     pInputText
                     formControlName="firstName">
              <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                   class="block p-error">
                <div *ngIf="firstName.errors?.['required']">Please enter your First Name.</div>
                <div *ngIf="firstName?.errors?.['pattern']">The First Name contains invalid characters.</div>
              </div>
            </div>
            <div class="field col-12 md:col-6 pt-2">
              <label for="lastname">Last Name</label>
              <input id="lastname"
                     type="text"
                     class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                     pInputText
                     formControlName="lastName">
              <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
                   class="block p-error">
                <div *ngIf="lastName.errors?.['required']">Please enter your Last Name.</div>
                <div *ngIf="lastName?.errors?.['pattern']">The Last Name contains invalid characters.</div>
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label for="email">Email</label>
              <input id="email"
                     type="email"
                     class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                     pInputText
                     formControlName="email">
              <div *ngIf="email.invalid && (email.dirty || email.touched)"
                   class="block p-error">
                <div *ngIf="email.errors?.['required']">Please enter your Email address.</div>
                <div *ngIf="email.errors?.['email']">Please enter a valid Email address.</div>
                <div *ngIf="email.errors?.['emailTaken']">
                  The Email address you entered is already in use. Please use a
                  different Email.
                </div>
              </div>
            </div>
            <div class="field col-12 md:col-6 mt-5">
              <div class="flex align-items-center justify-content-start">
                <label for="isAdmin" class="form-label">Grant Admin Access</label>
                <p-inputSwitch id="isAdmin" (click)="onAdminClick($event)" formControlName="isAdmin"></p-inputSwitch>
              </div>
            </div>
          </div>
        </form>
        <div class="flex justify-content-end py-4 gap-2">
          <p-button label="Next" id="usersNextButton"
                    [disabled]="personalInfoForm.invalid"
                    (onClick)="onStep1UserPanelNextClick($event)" />
        </div>
      </ng-template>
    </p-stepperPanel>
    <p-stepperPanel header="Manage Campaign Access">
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
        <div *ngIf="showCampaignSearchLoader" class="loader-container">
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div *ngIf="!showCampaignSearchLoader">
          <div class="flex flex-column h-36rem">
            <div class="border-2 border-solid surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
              <div class="campaign-search">
                <form [formGroup]="searchForm">
                  <div class="search-bar">
                    <input type="text"
                           id="campaignSearchInput"
                           pInputText
                           placeholder="Search campaigns..."
                           formControlName="search"
                           (input)="filterCampaigns()" />
                  </div>
                  <div class="campaign-list">
                    <div class="grid" *ngIf="filteredCampaigns && filteredCampaigns.length > 0">
                      <ng-container *ngFor="let campaign of filteredCampaigns">
                        <div class="col-4" *ngIf="!campaign.isEntityCampaign">
                          <p-checkbox [formControlName]="campaign.id"
                                      (onChange)="onCampaignSelected($event, searchForm.controls[campaign.id], campaign.id, campaign.name, campaign.isEntityCampaign)"
                                      binary="true"
                                      [label]="campaign.name"></p-checkbox>
                        </div>

                        <ng-container *ngIf="campaign.isEntityCampaign">
                          <div class="col-4" *ngFor="let childCampaign of campaign.childCampaigns">
                            <p-checkbox [formControlName]="campaign.id + '-' + childCampaign.id"
                                        (onChange)="onChildCampaignSelected($event, searchForm.controls[campaign.id + '-' + childCampaign.id], campaign.id, campaign.name, campaign.isEntityCampaign, childCampaign.id, childCampaign.name)"
                                        binary="true"
                                        [label]="campaign.name + '-' + childCampaign.name"></p-checkbox>
                          </div>
                        </ng-container>


                      </ng-container>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="flex justify-content-end py-4 gap-2">
            <p-button label="Previous" id="campaignPrevBtn" severity="secondary" (onClick)="prevCallback.emit()" />
            <p-button label="Next" id="campaignNextBtn" [disabled]="continueToStep3()" (onClick)="nextCallback.emit()" />
          </div>
        </div>

      </ng-template>
    </p-stepperPanel>
    <p-stepperPanel header="Manage Campaign Permissions">
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
        <form [formGroup]="userInviteForm" (ngSubmit)="onSubmitUserInviteForm()">
          <div *ngIf="step3DataIsInitialized" class="flex flex-column h-36rem">
            <div class="border-2 border-solid surface-border border-round surface-ground font-medium">

              <div formGroupName="allAccess">
                <div class="grid expandable-div dotted-bottom-div">
                  <div class="col-6">
                    <div id="selectedCampaignsCount" class="flex justify-content-start align-content-center">
                      Selected Campaigns:<span class="circle-number">{{selectedCampaigns.length}}</span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="flex justify-content-end align-content-center">
                      <p>
                        Apply the following settings to ALL selected campaigns, if they share the same settings.
                        <br> You will be able to adjust settings to individual campaigns below.
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Apply All Vortex3  -->
                <div id="vortex3Settings" formGroupName="vortex3" class="expandable-div dotted-bottom-div">
                  <!-- Div Header with expand/collapse button -->
                  <div class="grid">
                    <div class="col-6 header-title">
                      <div class="flex justify-content-start pl-2 gap-2">
                        <div class="align-content-center">
                          <p-button size="small"
                                    id="vortex3AccessToggleBtn"
                                    [label]="getAllAccessProductDisabled('vortex3')? 'Disabled': 'Enabled'"
                                    [icon]="getAllAccessProductDisabled('vortex3') ? 'pi pi-ban' : 'pi pi-check'"
                                    [ngClass]="{'p-disabled': getAllAccessProductDisabled('vortex3') }"
                                    (click)="onAllAccessProductClicked('vortex3')" />
                        </div>
                        <div class="align-content-center">
                          Vortex 3
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="flex justify-content-end gap-1">
                        <div class="align-content-center">
                          <i class="pi pi-info-circle"></i>
                        </div>
                        <div class="align-content-center">
                          Feature Access
                        </div>
                        <div>
                          <button pButton
                                  id="v3ToggleBtn"
                                  icon="{{ isVortex3Collapsed ? 'pi pi-plus' : 'pi pi-minus' }}"
                                  class="p-button-rounded p-button-text"
                                  (click)="toggleVortex3Collapse()"></button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Collapsible Content -->
                  <div *ngIf="!isVortex3Collapsed" class="content">
                    <div class="grid" fxLayoutAlign="start stretch">
                      <!-- Table 1 -->
                      <div class="col-6">
                        <table class="feature-table">
                          <thead>
                            <tr>
                              <th>Features</th>
                              <th>No Access</th>
                              <th>Read Only</th>
                              <th>Full Access</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let feature of vortex3FirstTableFeatures">
                              <td>{{ feature.name }}</td>
                              <td>
                                <p-radioButton [name]="getAllFeatureName(feature, vortex3)"
                                               [inputId]="getAllFeatureName(feature, vortex3)"
                                               value="NoAccess"
                                               (click)="onAllCampaignFeatureClick(feature, 'NoAccess')"
                                               [formControlName]="getAllFeatureName(feature, vortex3)"></p-radioButton>
                              </td>
                              <td>
                                <p-radioButton *ngIf="showRadioButton(feature)" [name]="getAllFeatureName(feature, vortex3)"
                                               [inputId]="getAllFeatureName(feature, vortex3)"
                                               value="ReadOnly"
                                               (click)="onAllCampaignFeatureClick(feature, 'ReadOnly')"
                                               [formControlName]="getAllFeatureName(feature, vortex3)"></p-radioButton>

                                <span *ngIf="!showRadioButton(feature)">N/A</span>
                              </td>
                              <td>
                                <p-radioButton [name]="getAllFeatureName(feature, vortex3)"
                                               [inputId]="getAllFeatureName(feature, vortex3)"
                                               value="FullAccess"
                                               (click)="onAllCampaignFeatureClick(feature, 'FullAccess')"
                                               [formControlName]="getAllFeatureName(feature, vortex3)"></p-radioButton>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <!-- Table 2 -->
                      <div class="col-6">
                        <table class="feature-table">
                          <thead>
                            <tr>
                              <th>Features</th>
                              <th>No Access</th>
                              <th>Read Only</th>
                              <th>Full Access</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let feature of vortex3SecondTableFeatures">
                              <td>{{ feature.name }}</td>
                              <td>
                                <p-radioButton [name]="getAllFeatureName(feature, vortex3)"
                                               [inputId]="getAllFeatureName(feature, vortex3)"
                                               value="NoAccess"
                                               (click)="onAllCampaignFeatureClick(feature, 'NoAccess')"
                                               [formControlName]="getAllFeatureName(feature, vortex3)"></p-radioButton>
                              </td>
                              <td>
                                <p-radioButton *ngIf="showRadioButton(feature)" [name]="getAllFeatureName(feature, vortex3)"
                                               [inputId]="getAllFeatureName(feature, vortex3)"
                                               value="ReadOnly"
                                               (click)="onAllCampaignFeatureClick(feature, 'ReadOnly')"
                                               [formControlName]="getAllFeatureName(feature, vortex3)"></p-radioButton>

                                <span *ngIf="!showRadioButton(feature)">N/A</span>
                              </td>
                              <td>
                                <p-radioButton [name]="getAllFeatureName(feature, vortex3)"
                                               [inputId]="getAllFeatureName(feature, vortex3)"
                                               value="FullAccess"
                                               (click)="onAllCampaignFeatureClick(feature, 'FullAccess')"
                                               [formControlName]="getAllFeatureName(feature, vortex3)"></p-radioButton>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div formGroupName="gl" class="grid mt-2">
                      <div class="col-12 pb-0">
                        <h3>General Ledger Access</h3>
                      </div>
                      <div class="col-3">
                        <label for="division">Division</label>
                        <input id="division" pInputText formControlName="division" placeholder="Division" />
                        <div *ngIf="getAllAccessVortex3GlDivision.invalid && getAllAccessVortex3GlDivision.touched" class="block p-error">
                          <div *ngIf="getAllAccessVortex3GlDivision.hasError('min')">Enter a value of at least 1.</div>
                          <div *ngIf="getAllAccessVortex3GlDivision.hasError('max')">Value must not exceed 9.</div>
                          <div *ngIf="getAllAccessVortex3GlDivision.hasError('pattern')">Only numbers are allowed.</div>
                        </div>
                      </div>

                      <div class="col-3">
                        <label for="department">Department</label>
                        <input id="department" pInputText formControlName="department" placeholder="Department" />
                        <div *ngIf="getAllAccessVortex3GlDepartment.invalid && getAllAccessVortex3GlDepartment.touched" class="block p-error">
                          <div *ngIf="getAllAccessVortex3GlDepartment.hasError('min')">Enter a value of at least 10.</div>
                          <div *ngIf="getAllAccessVortex3GlDepartment.hasError('max')">Value must not exceed 99.</div>
                          <div *ngIf="getAllAccessVortex3GlDepartment.hasError('pattern')">Only numbers are allowed.</div>
                        </div>
                      </div>

                      <div class="col-3">
                        <label for="subDepartment">Sub Department</label>
                        <input id="subDepartment" pInputText formControlName="subDepartment" placeholder="Sub Department" />
                        <div *ngIf="getAllAccessVortex3GlSubDepartment.invalid && getAllAccessVortex3GlSubDepartment.touched" class="block p-error">
                          <div *ngIf="getAllAccessVortex3GlSubDepartment.hasError('min')">Enter a value of at least 100.</div>
                          <div *ngIf="getAllAccessVortex3GlSubDepartment.hasError('max')">Value must not exceed 999.</div>
                          <div *ngIf="getAllAccessVortex3GlSubDepartment.hasError('pattern')">Only numbers are allowed.</div>
                        </div>
                      </div>

                      <div class="col-3">
                        <label for="role">Reports To</label>
                        <div>
                          <p-dropdown [options]="usersList"
                                      label="Reports To"
                                      optionLabel="name"
                                      optionValue="id"
                                      formControlName="reportsTo"
                                      placeholder="Select a Person">

                          </p-dropdown>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>


                <!-- Apply All Vortex Lite  -->
                <div formGroupName="vortexLite" class="expandable-div dotted-bottom-div">
                  <!-- Div Header with expand/collapse button-->
                  <div class="grid">
                    <div class="col-6 header-title">
                      <div class="flex justify-content-start pl-2 gap-2">
                        <div class="align-content-center">
                          <p-button size="small"
                                    id="vortexLiteAccessToggleBtn"
                                    [label]="getAllAccessProductDisabled('vortexLite')? 'Disabled': 'Enabled'"
                                    [icon]="getAllAccessProductDisabled('vortexLite') ? 'pi pi-ban' : 'pi pi-check'"
                                    [ngClass]="{'p-disabled': getAllAccessProductDisabled('vortexLite') }"
                                    (click)="onAllAccessProductClicked('vortexLite')" />
                        </div>
                        <div class="align-content-center">
                          Vortex Lite
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="flex justify-content-end gap-1">
                        <div class="align-content-center">
                          <i class="pi pi-info-circle"></i>
                        </div>
                        <div class="align-content-center">
                          Feature Access
                        </div>
                        <div>
                          <button pButton
                                  id="liteToggleBtn"
                                  icon="{{ isAllVortexLiteCollapsed ? 'pi pi-plus' : 'pi pi-minus' }}"
                                  class="p-button-rounded p-button-text"
                                  (click)="toggleVortexLiteCollapse()"></button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Collapsible Content-->
                  <div *ngIf="!isAllVortexLiteCollapsed" class="content">
                    <div class="grid" fxLayoutAlign="start stretch">
                      <!--  Table 1-->
                      <div class="col-6">
                        <table class="feature-table">
                          <thead>
                            <tr>
                              <th>Features</th>
                              <th>No Access</th>
                              <th>Read Only</th>
                              <th>Full Access</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let feature of vortexLiteFirstTableFeatures">
                              <td>{{ feature.name }}</td>
                              <td>
                                <p-radioButton [name]="getAllFeatureName(feature, vortexLite)"
                                               [inputId]="getAllFeatureName(feature, vortexLite)"
                                               value="NoAccess"
                                               (click)="onAllVortexLiteFeatureClick(feature, 'NoAccess')"
                                               [formControlName]="getAllFeatureName(feature, vortexLite)"></p-radioButton>
                              </td>
                              <td>
                                <p-radioButton [name]="getAllFeatureName(feature, vortexLite)"
                                               [inputId]="getAllFeatureName(feature, vortexLite)"
                                               value="ReadOnly"
                                               (click)="onAllVortexLiteFeatureClick(feature, 'ReadOnly')"
                                               [formControlName]="getAllFeatureName(feature, vortexLite)"></p-radioButton>
                              </td>
                              <td>
                                <p-radioButton [name]="getAllFeatureName(feature, vortexLite)"
                                               [inputId]="getAllFeatureName(feature, vortexLite)"
                                               value="FullAccess"
                                               (click)="onAllVortexLiteFeatureClick(feature, 'FullAccess')"
                                               [formControlName]="getAllFeatureName(feature, vortexLite)"></p-radioButton>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>
                </div>

                <!-- Apply All Apex  -->
                <div formGroupName="apex" class="expandable-div dotted-bottom-div">
                  <!-- Div Header with expand/collapse button-->
                  <div class="grid">
                    <div class="col-6 header-title">
                      <div class="flex justify-content-start pl-2 gap-2">
                        <div class="align-content-center">
                          <p-button size="small"
                                    id="apexAccessToggleBtn"
                                    [label]="getAllAccessProductDisabled('apex')? 'Disabled': 'Enabled'"
                                    [icon]="getAllAccessProductDisabled('apex') ? 'pi pi-ban' : 'pi pi-check'"
                                    [ngClass]="{'p-disabled': getAllAccessProductDisabled('apex') }"
                                    (click)="onAllAccessProductClicked('apex')" />
                        </div>
                        <div class="align-content-center">
                          APEX
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="flex justify-content-end gap-1">
                        <div class="align-content-center">
                          <i class="pi pi-info-circle"></i>
                        </div>
                        <div class="align-content-center">
                          Feature Access
                        </div>
                        <div>
                          <button pButton
                                  id="apxToggleBtn"
                                  icon="{{ isAllApexCollapsed ? 'pi pi-plus' : 'pi pi-minus' }}"
                                  class="p-button-rounded p-button-text"
                                  (click)="toggleApexCollapse()"></button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Collapsible Content-->
                  <div *ngIf="!isAllApexCollapsed" class="content">
                    <div class="grid" fxLayoutAlign="start stretch">
                      <!-- Table 1-->
                      <div class="col-6">
                        <table class="feature-table">
                          <thead>
                            <tr>
                              <th>Features</th>
                              <th>No Access</th>
                              <th>Read Only</th>
                              <th>Full Access</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let feature of apexFirstTableFeatures">
                              <td>{{ feature.name }}</td>
                              <td>
                                <p-radioButton [name]="getAllFeatureName(feature, apex)"
                                               [inputId]="getAllFeatureName(feature, apex)"
                                               value="NoAccess"
                                               (click)="onAllVortexLiteFeatureClick(feature, 'NoAccess')"
                                               [formControlName]="getAllFeatureName(feature, apex)"></p-radioButton>
                              </td>
                              <td>
                                <p-radioButton [name]="getAllFeatureName(feature, apex)"
                                               [inputId]="getAllFeatureName(feature, apex)"
                                               value="ReadOnly"
                                               (click)="onAllVortexLiteFeatureClick(feature, 'ReadOnly')"
                                               [formControlName]="getAllFeatureName(feature, apex)"></p-radioButton>
                              </td>
                              <td>
                                <p-radioButton [name]="getAllFeatureName(feature, apex)"
                                               [inputId]="getAllFeatureName(feature, apex)"
                                               value="FullAccess"
                                               (click)="onAllVortexLiteFeatureClick(feature, 'FullAccess')"
                                               [formControlName]="getAllFeatureName(feature, apex)"></p-radioButton>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

              <div class="flex justify-content-start mb-3 mt-3 pl-5">
                <p-button size="small" (click)="applyAll($event)" label="Apply to All" />
              </div>

              <p-accordion formArrayName="userInviteCampaigns" class="full-width-accordion" [style]="{'background-color': '#f5f5f5', 'border-radius': '10px'}">
                <!--<p-accordionTab *ngFor="let campaign of selectedCampaigns" [header]="campaign.name">-->
                <p-accordionTab *ngFor="let campaign of step3Campaigns.controls; let i = index; trackBy: trackByIndex" [formGroupName]="i" [header]="step3Campaigns.at(i).get('name')?.value || 'campaign'">

                  <!-- Child Campaigns -->
                  <div formArrayName="childCampaigns" *ngIf="showChildCampaigns(i)" class="grid expandable-div dotted-bottom-div content">
                    <div class="col-12 pb-0 pt-0">
                      <h3>Selected Child Campaigns</h3>
                    </div>
                    <div class="col-4" *ngFor="let childCampaign of getChildCampaigns(i).controls; let j = index" [formGroupName]="j">
                      <p-checkbox binary="true"
                                  [label]="getChildCampaignName(i,j)"
                                  formControlName="selected"
                                  [readonly]="true"></p-checkbox>
                    </div>
                  </div>

                  <!-- Apply Campaign Vortex3  -->
                  <div class="expandable-div dotted-bottom-div">
                    <!-- Div Header with expand/collapse button -->
                    <div class="grid">
                      <div class="col-6 header-title">
                        <div class="flex justify-content-start pl-2 gap-2">
                          <div class="align-content-center">
                            <p-button size="small"
                                      [label]="getCampaignProductDisabled(i, 'campaignVortex3')? 'Disabled': 'Enabled'"
                                      [icon]="getCampaignProductDisabled(i, 'campaignVortex3') ? 'pi pi-ban' : 'pi pi-check'"
                                      [ngClass]="{'p-disabled': getCampaignProductDisabled(i, 'campaignVortex3') }"
                                      (click)="onCampaignProductClicked(i, 'campaignVortex3')" />
                          </div>
                          <div class="align-content-center">
                            Vortex 3
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="flex justify-content-end gap-1">
                          <div class="align-content-center">
                            <i class="pi pi-info-circle"></i>
                          </div>
                          <div class="align-content-center">
                            Feature Access
                          </div>
                          <div>
                            <button pButton
                                    icon="{{ isVortex3ExpandedArray[i] ?  'pi pi-minus' : 'pi pi-plus' }}"
                                    class="p-button-rounded p-button-text"
                                    (click)="toggleVortex3ExpandIndex(i)"></button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Collapsible Content -->
                    <div *ngIf="isVortex3ExpandedArray[i]" class="content">
                      <div formGroupName="campaignVortex3" fxLayoutAlign="start stretch">
                        <div class="grid">
                          <!-- Table 1 -->
                          <div class="col-6">
                            <table class="feature-table">
                              <thead>
                                <tr>
                                  <th>Features</th>
                                  <th>No Access</th>
                                  <th>Read Only</th>
                                  <th>Full Access</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let feature of vortex3FirstTableFeatures">
                                  <td>{{ feature.name }}</td>
                                  <td>
                                    <p-radioButton [name]="getFeatureName(campaign, feature, vortex3)"
                                                   [inputId]="getFeatureName(campaign, feature, vortex3)"
                                                   value="NoAccess"
                                                   (click)="onCampaignFeatureClick('campaignVortex3', i, campaign, vortex3, feature, 'NoAccess')"
                                                   [formControlName]="getFeatureName(campaign, feature, vortex3)"></p-radioButton>
                                  </td>
                                  <td>
                                    <p-radioButton *ngIf="showRadioButton(feature)" [name]="getFeatureName(campaign, feature, vortex3)"
                                                   [inputId]="getFeatureName(campaign, feature, vortex3)"
                                                   value="ReadOnly"
                                                   (click)="onCampaignFeatureClick('campaignVortex3',i, campaign, vortex3, feature, 'ReadOnly')"
                                                   [formControlName]="getFeatureName(campaign, feature, vortex3)"></p-radioButton>

                                    <span *ngIf="!showRadioButton(feature)">N/A</span>
                                  </td>
                                  <td>
                                    <p-radioButton [name]="getFeatureName(campaign, feature, vortex3)"
                                                   [inputId]="getFeatureName(campaign, feature, vortex3)"
                                                   value="FullAccess"
                                                   (click)="onCampaignFeatureClick('campaignVortex3', i, campaign, vortex3, feature, 'FullAccess')"
                                                   [formControlName]="getFeatureName(campaign, feature, vortex3)"></p-radioButton>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <!-- Table 2 -->
                          <div class="col-6">
                            <table class="feature-table">
                              <thead>
                                <tr>
                                  <th>Features</th>
                                  <th>No Access</th>
                                  <th>Read Only</th>
                                  <th>Full Access</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let feature of vortex3SecondTableFeatures">
                                  <td>{{ feature.name }}</td>
                                  <td>
                                    <p-radioButton [name]="getFeatureName(campaign, feature, vortex3)"
                                                   [inputId]="getFeatureName(campaign, feature, vortex3)"
                                                   value="NoAccess"
                                                   (click)="onCampaignFeatureClick('campaignVortex3', i, campaign, vortex3, feature, 'NoAccess')"
                                                   [formControlName]="getFeatureName(campaign, feature, vortex3)"></p-radioButton>
                                  </td>
                                  <td>
                                    <p-radioButton *ngIf="showRadioButton(feature)" [name]="getFeatureName(campaign, feature, vortex3)"
                                                   [inputId]="getFeatureName(campaign, feature, vortex3)"
                                                   value="ReadOnly"
                                                   (click)="onCampaignFeatureClick('campaignVortex3', i, campaign, vortex3, feature, 'ReadOnly')"
                                                   [formControlName]="getFeatureName(campaign, feature, vortex3)"></p-radioButton>

                                    <span *ngIf="!showRadioButton(feature)">N/A</span>
                                  </td>
                                  <td>
                                    <p-radioButton [name]="getFeatureName(campaign, feature, vortex3)"
                                                   [inputId]="getFeatureName(campaign, feature, vortex3)"
                                                   value="FullAccess"
                                                   (click)="onCampaignFeatureClick('campaignVortex3', i, campaign, vortex3, feature, 'FullAccess')"
                                                   [formControlName]="getFeatureName(campaign, feature, vortex3)"></p-radioButton>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div formGroupName="gl" class="grid mt-2">
                          <div class="col-12 pb-0">
                            <h3>General Ledger Access</h3>
                          </div>
                          <div class="col-3">
                            <label for="division">Division</label>
                            <input id="division" pInputText formControlName="division" placeholder="Division" />
                            <div *ngIf="getCampaignVortex3GlControl(i, 'division').invalid && getCampaignVortex3GlControl(i, 'division').touched" class="block p-error">
                              <div *ngIf="getCampaignVortex3GlControl(i, 'division').hasError('min')">Enter a value of at least 1.</div>
                              <div *ngIf="getCampaignVortex3GlControl(i, 'division').hasError('max')">Value must not exceed 9.</div>
                              <div *ngIf="getCampaignVortex3GlControl(i, 'division').hasError('pattern')">Only numbers are allowed.</div>
                            </div>
                          </div>

                          <div class="col-3">
                            <label for="department">Department</label>
                            <input id="department" pInputText formControlName="department" placeholder="Department" />
                            <div *ngIf="getCampaignVortex3GlControl(i, 'department').invalid && getCampaignVortex3GlControl(i, 'department').touched" class="block p-error">
                              <div *ngIf="getCampaignVortex3GlControl(i, 'department').hasError('min')">Enter a value of at least 10.</div>
                              <div *ngIf="getCampaignVortex3GlControl(i, 'department').hasError('max')">Value must not exceed 99.</div>
                              <div *ngIf="getCampaignVortex3GlControl(i, 'department').hasError('pattern')">Only numbers are allowed.</div>
                            </div>
                          </div>

                          <div class="col-3">
                            <label for="subDepartment">Sub Department</label>
                            <input id="subDepartment" pInputText formControlName="subDepartment" placeholder="Sub Department" />
                            <div *ngIf="getCampaignVortex3GlControl(i, 'subDepartment').invalid && getCampaignVortex3GlControl(i, 'subDepartment').touched" class="block p-error">
                              <div *ngIf="getCampaignVortex3GlControl(i, 'subDepartment').hasError('min')">Enter a value of at least 100.</div>
                              <div *ngIf="getCampaignVortex3GlControl(i, 'subDepartment').hasError('max')">Value must not exceed 999.</div>
                              <div *ngIf="getCampaignVortex3GlControl(i, 'subDepartment').hasError('pattern')">Only numbers are allowed.</div>
                            </div>
                          </div>

                          <div class="col-3">
                            <label for="role">Reports To</label>
                            <div>
                              <p-dropdown [options]="usersList"
                                          label="Reports To"
                                          formControlName="reportsTo"
                                          optionLabel="name"
                                          optionValue="id"
                                          placeholder="Select a Person">

                              </p-dropdown>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <!-- Apply Campaign Vortex Lite  -->
                  <div formGroupName="campaignVortexLite" class="expandable-div dotted-bottom-div">
                    <!-- Div Header with expand/collapse button-->
                    <div class="grid">
                      <div class="col-6 header-title">
                        <div class="flex justify-content-start pl-2 gap-2">
                          <div class="align-content-center">
                            <p-button size="small"
                                      [label]="getCampaignProductDisabled(i, 'campaignVortexLite')? 'Disabled': 'Enabled'"
                                      [icon]="getCampaignProductDisabled(i, 'campaignVortexLite') ? 'pi pi-ban' : 'pi pi-check'"
                                      [ngClass]="{'p-disabled': getCampaignProductDisabled(i, 'campaignVortexLite') }"
                                      (click)="onCampaignProductClicked(i, 'campaignVortexLite')" />
                            <!--<p-button size="small" label="Enabled" icon="pi pi-check" />-->
                          </div>
                          <div class="align-content-center">
                            Vortex Lite
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="flex justify-content-end gap-1">
                          <div class="align-content-center">
                            <i class="pi pi-info-circle"></i>
                          </div>
                          <div class="align-content-center">
                            Feature Access
                          </div>
                          <div>
                            <button pButton
                                    icon="{{ isVortexLiteExpandedArray[i] ?  'pi pi-minus' : 'pi pi-plus' }}"
                                    class="p-button-rounded p-button-text"
                                    (click)="toggleVortexLiteExpandIndex(i)"></button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--Collapsible Content-->
                    <div *ngIf="isVortexLiteExpandedArray[i]" class="content">
                      <div class="grid" fxLayoutAlign="start stretch">
                        <!--  Table 1-->
                        <div class="col-6">
                          <table class="feature-table">
                            <thead>
                              <tr>
                                <th>Features</th>
                                <th>No Access</th>
                                <th>Read Only</th>
                                <th>Full Access</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let feature of vortexLiteFirstTableFeatures">
                                <td>{{ feature.name }}</td>
                                <td>
                                  <p-radioButton [name]="getFeatureName(campaign, feature, vortexLite)"
                                                 [inputId]="getFeatureName(campaign, feature, vortexLite)"
                                                 value="NoAccess"
                                                 (click)="onCampaignFeatureClick('campaignVortexLite', i, campaign, vortexLite, feature, 'NoAccess')"
                                                 [formControlName]="getFeatureName(campaign, feature, vortexLite)"></p-radioButton>
                                </td>
                                <td>
                                  <p-radioButton [name]="getFeatureName(campaign, feature, vortexLite)"
                                                 [inputId]="getFeatureName(campaign, feature, vortexLite)"
                                                 value="ReadOnly"
                                                 (click)="onCampaignFeatureClick('campaignVortexLite', i, campaign, vortexLite, feature, 'ReadOnly')"
                                                 [formControlName]="getFeatureName(campaign, feature, vortexLite)"></p-radioButton>

                                </td>
                                <td>
                                  <p-radioButton [name]="getFeatureName(campaign, feature, vortexLite)"
                                                 [inputId]="getFeatureName(campaign, feature, vortexLite)"
                                                 value="FullAccess"
                                                 (click)="onCampaignFeatureClick('campaignVortexLite', i, campaign, vortexLite, feature, 'FullAccess')"
                                                 [formControlName]="getFeatureName(campaign, feature, vortexLite)"></p-radioButton>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>

                  <!-- Apply All Apex  -->
                  <div formGroupName="campaignApex" class="expandable-div dotted-bottom-div">
                    <!-- Div Header with expand/collapse button-->
                    <div class="grid">
                      <div class="col-6 header-title">
                        <div class="flex justify-content-start pl-2 gap-2">
                          <div class="align-content-center">
                            <p-button size="small"
                                      [label]="getCampaignProductDisabled(i, 'campaignApex')? 'Disabled': 'Enabled'"
                                      [icon]="getCampaignProductDisabled(i, 'campaignApex') ? 'pi pi-ban' : 'pi pi-check'"
                                      [ngClass]="{'p-disabled': getCampaignProductDisabled(i, 'campaignApex') }"
                                      (click)="onCampaignProductClicked(i, 'campaignApex')" />
                            <!--<p-button size="small" label="Enabled" icon="pi pi-check" />-->
                          </div>
                          <div class="align-content-center">
                            Apex
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="flex justify-content-end gap-1">
                          <div class="align-content-center">
                            <i class="pi pi-info-circle"></i>
                          </div>
                          <div class="align-content-center">
                            Feature Access
                          </div>
                          <div>
                            <button pButton
                                    icon="{{ isApexExpandedArray[i] ?  'pi pi-minus' : 'pi pi-plus' }}"
                                    class="p-button-rounded p-button-text"
                                    (click)="toggleApexExpandIndex(i)"></button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--Collapsible Content-->
                    <div *ngIf="isApexExpandedArray[i]" class="content">
                      <div class="grid" fxLayoutAlign="start stretch">
                        <!-- Table 1-->
                        <div class="col-6">
                          <table class="feature-table">
                            <thead>
                              <tr>
                                <th>Features</th>
                                <th>No Access</th>
                                <th>Read Only</th>
                                <th>Full Access</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let feature of apexFirstTableFeatures">
                                <td>{{ feature.name }}</td>
                                <td>
                                  <p-radioButton [name]="getFeatureName(campaign, feature, apex)"
                                                 [inputId]="getFeatureName(campaign, feature, apex)"
                                                 value="NoAccess"
                                                 (click)="onCampaignFeatureClick('campaignApex', i, campaign, apex, feature, 'NoAccess')"
                                                 [formControlName]="getFeatureName(campaign, feature, apex)"></p-radioButton>
                                </td>
                                <td>
                                  <p-radioButton [name]="getFeatureName(campaign, feature, apex)"
                                                 [inputId]="getFeatureName(campaign, feature, apex)"
                                                 value="ReadOnly"
                                                 (click)="onCampaignFeatureClick('campaignApex', i, campaign, apex, feature, 'ReadOnly')"
                                                 [formControlName]="getFeatureName(campaign, feature, apex)"></p-radioButton>

                                </td>
                                <td>
                                  <p-radioButton [name]="getFeatureName(campaign, feature, apex)"
                                                 [inputId]="getFeatureName(campaign, feature, apex)"
                                                 value="FullAccess"
                                                 (click)="onCampaignFeatureClick('campaignApex', i, campaign, apex, feature, 'FullAccess')"
                                                 [formControlName]="getFeatureName(campaign, feature, apex)"></p-radioButton>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-accordionTab>
              </p-accordion>

            </div>
          </div>
        </form>

        <div class="flex justify-content-end py-4 gap-2">
          <p-button label="Previous" id="inviteUserPreviousBtn" severity="secondary" (onClick)="prevCallback.emit()" />
          <p-button label="Invite User" id="inviteUserNextBtn" (onClick)="inviteUser($event)" />
        </div>

      </ng-template>
    </p-stepperPanel>
  </p-stepper>
</div>
