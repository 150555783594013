import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ChildrenOutletContexts, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { ServerClientService } from '../../global/services/data/CampaignService/server-client.service';
import { MainModel } from '../../global/model/MainModel';
import NavModel from '../../global/model/NavModel';
import { DashboardComponent } from '../../global/page/dashboard/dashboard.component';
import { BreadcrumbComponent } from "../../global/component/breadcrumb/breadcrumb.component";
import { AppBarComponent } from "../../global/component/app-bar/app-bar.component";
import { CampaignSwitcherComponent } from "../../global/component/campaign-switcher/campaign-switcher.component";
import { MainNavComponent } from "../../global/component/main-nav/main-nav.component";
import { ToastModule } from 'primeng/toast';
import { NgIf } from '@angular/common';
import {Location} from '@angular/common';
import { DisplayService, SCREEN_PROFILE } from '../../global/services/ui/DisplayService';

@Component({
  selector: 'app-default',
  standalone: true,
  imports: [RouterOutlet, NgIf, BreadcrumbComponent, AppBarComponent, CampaignSwitcherComponent, MainNavComponent, ToastModule],
  templateUrl: './default.component.html',
  styleUrl: './default.component.scss'
})

export class DefaultComponent implements OnInit{
  isOpen = true;
  isShowBreadcrumb:boolean = false;
  displayComponent:any = DashboardComponent;
  currentApp: string = "admin";
  campaignList: any = [];
  SCREEN_PROFILE = SCREEN_PROFILE;

  toggle() {
    console.log(this.model.navigation.currentState)
    //this.isOpen = !this.isOpen;
    this.model.navigation.toggleMainNavState();
  }

  constructor(
    private serverClient: ServerClientService, 
    private contexts: ChildrenOutletContexts|null, 
    protected model:MainModel,
    private router:Router,
    private activeRoute:ActivatedRoute,
    private _location: Location,
    protected display:DisplayService
    ) {
      router.events.subscribe((value:any) => {
        if(value instanceof NavigationEnd){
              
          if(value instanceof NavigationEnd){
              console.debug(this.activeRoute);
              }
          }
        });
        
    };

    goBack(){
      this._location.back();
    }

    toggleMenu(){
      this.model.navigation.toggleMainNavState();
    }

    ngOnInit() {
      
    }
    
}
