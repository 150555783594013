import { Component, Injectable, signal, WritableSignal } from "@angular/core";
import { Observable } from "rxjs";
import Campaign from "./Campaign";
import { DashboardComponent } from "../page/dashboard/dashboard.component";
import { MenuItem } from "primeng/api";
import NavModel, { NavOrder } from "./NavModel";
import App from "./App";
import { UserPermissionsService } from "../services/permissions/UserPermissionsService";

export class UserModel {

    favorites:Map<string,string> = new Map<string, string>();

    id:string|undefined;
    name:string = "";
}

/**
 * This model is the base model to bind to. Sub models may be defined if needed. 
 */
@Injectable({
    providedIn: 'root',
  })
export class MainModel {

    
    constructor(
        private permissionsAPI_IN:UserPermissionsService,
    ){
    
    }
    
    // area specific models...
    user:UserModel = new UserModel();
    navigation:NavModel = new NavModel();
    // global model attributes...
    campaigns:Array<any> = []; // by order...
    campaignMap:Map<string, Campaign> = new Map<string,Campaign>(); // access by id...
    getMappedCampaign(id:string|undefined):Campaign|undefined|void{
        if(this.campaignMap.entries.length === 0){
            this.campaigns.forEach( (i:Campaign) => {
                this.campaignMap.set(i.campaignId, i);
            });
        }
        if(id)return this.campaignMap.get(id);
    }

    // global model attributes that when changed should trigger data requests from the back end...
    lastAccessedCampaigns:Array<Campaign> = [];
    selectedCampaign: WritableSignal<Campaign|null> = signal(null);
    selectedApp: WritableSignal<App|null> = signal(null); // may need more then a literal here eventually...

    productsAndFeatures:Array<App> = [];
    /**
     * Pages/features a user can access - structured so a user can get or check the existence within the data structure easily.
     */
    apps:Map<String,any> = new Map();

    /**
     * 
     * @param arrayIn top level apps
     */
    updateNavigationModel(entries:Array<App>){
        const products:Array<App> = [];
        // sorting for the applications by preferred nav order...
        NavOrder.forEach(
            (i:any) => {
                entries.forEach(
                    (x:App) => 
                        (x.name.toLowerCase() === i.label.toLowerCase()) && products.push(x)
                )
            }
        );
        this.productsAndFeatures = products;
        this.permissionsAPI_IN.clear();
        this.permissionsAPI_IN.ingestPermissionData(this.productsAndFeatures);
        //console.debug(this.permissionsAPI_IN.securityModel);
        this.apps.clear();
        //call navSrevie  to set defaults...

        this.productsAndFeatures.forEach(
            i => {
                this.apps.set(i.name.toLowerCase(),i);
            }
        );
    }
}   
