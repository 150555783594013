<!-- The logic for generating the component is duplicated for iconfield and non-iconfield... -->
<ng-container *ngIf="icon">
    <label [for]="name" class="field-label {{ isRequired === true ? 'required' : '' }}">
        {{label}}
    </label>
    <p-iconField iconPosition="left" *ngIf="icon"  class="icon-field">
        <p-inputIcon [styleClass]="iconClass" *ngIf="iconClass.length > 0" />
        
        @switch (type) {
            @case ("text") 
            {
                <input 
                    type="text" 
                    pInputText 
                    [class]="fullClassString"
                    [formControl]="control"
                    [placeholder]="placeholder" 
                    (keypress)="keyPressed($event)"
                    (change)="valueChanged($event)"
                    (focus)="focussed($event)"
                    (blur)="blurred($event)"
                    [formControl]="control"
                    />
            }
            @case ("calendar") {
                <p-calendar 
                    [styleClass]="fullClassString"
                    (keypress)="keyPressed($event)"
                    [placeholder]="placeholder" 
                    (change)="valueChanged($event)"
                    (focus)="focussed($event)"
                    (blur)="blurred($event)"
                    [formControl]="control"
                    [showIcon]="true" 
                    [iconDisplay]="'input'" 
                    />
            }
            @case ("password") {
                <p-password 
                    [feedback]="false"
                    [styleClass]="fullClassString"
                    [formControl]="control"
                    [placeholder]="placeholder" 
                    (keypress)="keyPressed($event)"
                    (change)="valueChanged($event)"
                    (focus)="focussed($event)"
                    (blur)="blurred($event)"
                    [formControl]="control"
                    />
            }
            @case ("masked") {
                <p-inputMask 
                    [mask]="mask" 
                    [placeholder]="placeholder"
                    [styleClass]="fullClassString"
                    [formControl]="control"
                    (keypress)="keyPressed($event)"
                    (change)="valueChanged($event)"
                    (focus)="focussed($event)"
                    (blur)="blurred($event)"
                    />
            }
            @case ("large-text") {
                <textarea 
                    rows="5"
                    cols="30"
                    pInputTextarea 
                    [formControl]="control"
                    [class]="fullClassString"
                    [placeholder]="placeholder" 
                    (keypress)="keyPressed($event)"
                    (change)="valueChanged($event)"
                    (focus)="focussed($event)"
                    (blur)="blurred($event)"
                    >
                </textarea>
            }
            @case ("auto-complete") {
                <p-autoComplete 
                    [styleClass]="fullClassString"
                    [placeholder]="placeholder" 
                    [suggestions]="selectValues"
                    [formControl]="control"
                    (keypress)="keyPressed($event)"
                    (change)="valueChanged($event)"
                    (focus)="focussed($event)"
                    (blur)="blurred($event)"
                    />
            }
            @case ("multi-select") {

                <p-multiSelect 
                    [styleClass]="fullClassString"
                    optionLabel="label" 
                    [options]="selectValues"
                    [formControl]="control"
                    [formControlName]="control"
                    [placeholder]="placeholder" 
                    (keypress)="keyPressed($event)"
                    (change)="valueChanged($event)"
                    (focus)="focussed($event)"
                    (blur)="blurred($event)"
                    [optionLabel]="optionLabel"
                    [showClear]="showClear"
                    display="chips"
                    ></p-multiSelect>
            }
            @case ("select") {
                <p-dropdown 
                    [styleClass]="fullClassString"
                    optionLabel="label" 
                    [options]="selectValues"
                    [formControl]="control"
                    [placeholder]="placeholder" 
                    (keypress)="keyPressed($event)"
                    (change)="valueChanged($event)"
                    (focus)="focussed($event)"
                    [optionLabel]="optionLabel"
                    (blur)="blurred($event)"
                    [showClear]="showClear"
                    />
            }
        }
    </p-iconField>
</ng-container>
<ng-container *ngIf="!icon">
    <label [for]="name" class="field-label {{ isRequired ? 'required' : '' }}">
        {{label}}
    </label>
    
    @switch (type) {
        @case ("text") 
        {
            <input 
                type="text" 
                pInputText 
                [class]="fullClassString"
                [placeholder]="placeholder" 
                (keypress)="keyPressed($event)"
                (change)="valueChanged($event)"
                (focus)="focussed($event)"
                (blur)="blurred($event)"
                [formControl]="control"
                />
                <!-- [(ngModel)]="value" -->
        }
        @case ("calendar") {
            <p-calendar 
                [styleClass]="fullClassString"
                [placeholder]="placeholder" 
                (keypress)="keyPressed($event)"
                (change)="valueChanged($event)"
                (focus)="focussed($event)"
                (blur)="blurred($event)"
                [formControl]="control"
                [showIcon]="true" 
                [iconDisplay]="'input'" 
                />
        }
        @case ("password") {
            <p-password 
                [styleClass]="fullClassString"
                [placeholder]="placeholder" 
                [feedback]="false"
                (keypress)="keyPressed($event)"
                (change)="valueChanged($event)"
                (focus)="focussed($event)"
                (blur)="blurred($event)"
                [formControl]="control"
                />
        }
        @case ("masked") {
            <p-inputMask 
                [styleClass]="fullClassString"
                [mask]="mask" 
                [placeholder]="placeholder"
                [formControl]="control"
                (keypress)="keyPressed($event)"
                (change)="valueChanged($event)"
                (focus)="focussed($event)"
                (blur)="blurred($event)"
                />
        }
        @case ("large-text") {
            <textarea 
                [class]="fullClassString"
                [placeholder]="placeholder" 
                rows="5"
                cols="30"
                pInputTextarea 
                [formControl]="control"
                (keypress)="keyPressed($event)"
                (change)="valueChanged($event)"
                (focus)="focussed($event)"
                (blur)="blurred($event)"
                >
            </textarea>
        }
        @case ("auto-complete") {
            <p-autoComplete 
                [styleClass]="fullClassString"
                [placeholder]="placeholder" 
                [suggestions]="selectValues"
                [formControl]="control"
                (keypress)="keyPressed($event)"
                (change)="valueChanged($event)"
                (focus)="focussed($event)"
                (blur)="blurred($event)"
                />
        }
        @case ("select") {
            <p-dropdown 
                [styleClass]="fullClassString"
                [placeholder]="placeholder" 
                optionLabel="label" 
                [options]="selectValues"
                [formControl]="control"
                (keypress)="keyPressed($event)"
                (change)="valueChanged($event)"
                (focus)="focussed($event)"
                [optionLabel]="optionLabel"
                (blur)="blurred($event)"
                >
            </p-dropdown>
        }
        @case ("multi-select") {
            
            <p-multiSelect *ngIf="display"
                [styleClass]="fullClassString"
                [placeholder]="placeholder" 
                optionLabel="label" 
                [options]="selectValues"
                [formControl]="control"
                [optionLabel]="optionLabel"
                (keypress)="keyPressed($event)"
                (change)="valueChanged($event)"
                (focus)="focussed($event)"
                (blur)="blurred($event)"
                [display]="display"
                />
                
            <p-multiSelect *ngIf="!display"
                [styleClass]="fullClassString"
                [placeholder]="placeholder" 
                optionLabel="label" 
                [options]="selectValues"
                [formControl]="control"
                [optionLabel]="optionLabel"
                (keypress)="keyPressed($event)"
                (change)="valueChanged($event)"
                (focus)="focussed($event)"
                (blur)="blurred($event)"
                />
        }
    }
</ng-container>

<small class="error" *ngIf="this.control && this.control.dirty && this.control.invalid" class="error">
    <div *ngIf="control.hasError('required')">
        {{label||name}} is required.
    </div>
    <!-- <div *ngIf="control.hasError('minlength')">
        {{label||name}} must be at least {{minLength}} characters long.
    </div>
    <div *ngIf="control.hasError('maxlength')">
        {{label||name}} must be no bigger then {{maxLength}} characters long.
    </div>
    <div *ngIf="control.hasError('min')">
        {{label||name}} must be more then {{minLength}}.
    </div>
    <div *ngIf="control.hasError('max')">
        {{label||name}} must be less then {{maxLength}}.
    </div> -->
    <div *ngIf="control.hasError('optionOneNotValid')">
        Ok
    </div>
</small>
