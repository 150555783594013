import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Button } from 'primeng/button';

const icomImages:Map<string,string> = new Map();
icomImages.set("compare","fa-sharp fa-light fa-code-compare");
icomImages.set("dropdown","fa-sharp fa-light fa-chevron-down");
icomImages.set("download","fa-sharp fa-light fa-download");
icomImages.set("trash","fa-sharp fa-light fa-trash-can");
icomImages.set("cog","fa-sharp fa-light fa-cog");
icomImages.set("xmark","fa-sharp fa-light fa-xmark-large");
icomImages.set("plus","fa-sharp fa-light fa-plus");
icomImages.set("filter","fa-sharp fa-light fa-filter");
icomImages.set("clear-filter","fa-sharp fa-light fa-filter-circle-xmark");
icomImages.set("search-money","fa-sharp fa-light fa-search-dollar");
icomImages.set("user-minus","fa-sharp fa-light fa-user-minus");
icomImages.set("user-clock","fa-sharp fa-light fa-user-clock");
icomImages.set("user-check","fa-sharp fa-light fa-user-check");
icomImages.set("envelope-dollar","fa-sharp fa-light fa-envelope-open-dollar");
icomImages.set("money-check","fa-sharp fa-light fa-money-check-dollar");
icomImages.set("upload","fa-sharp fa-light fa-upload");


@Component({
  selector: 'rc-generic-button',
  standalone: true,
  imports: [Button, NgClass],
  templateUrl: './generic-button.component.html',
  styleUrl: './generic-button.component.scss'
})
export class GenericButtonComponent {

  @Input() label:string = "";
  @Input() level: "primary" | "secondary" | "tertiary" | null | undefined  = "primary";
  @Input() iconImg: "download" | "dropdown" | "export" | "compare" | 
  "select" | "add-item" | "filter" | "clear-filter" | 
  "trash" | "cog" | "search-money" | "user-minus" | "xmark" | "plus" | 
   "user-clock" | "user-check" | "upload" | "envelope-dollar" | 
   "money-check" | null | undefined = undefined;
  @Input() borderStyle: "solid" | "outline" | "text-only" | "icon-only" | "link" = "solid";
  @Input() iconColor: "gray-light" | "teal-light" | "red-light" | "teal-medium" |"teal-dark" | "gray-dark" | "redwood-light" | undefined = undefined;
  @Input() size: "small" | "large" | undefined = undefined;
  @Input() href:string | null = null;
  @Input() round:boolean = false;
  @Input() disabled:boolean = false;
  @Input() isIconOnly:boolean = false;
  @Input() classes:Array<string> = [];
  @Output() clickHandler = new EventEmitter<Event>();

  fullClassString:string = "";

  icon:string|null = null

  constructor(private router:Router){
    if(this.iconImg)
      {
        const val = this.getIcon(this.iconImg);
        if(val !== null && val !== undefined)this.icon = val;
      }
  }

  clicked($event:Event){
    console.debug("Button click()", $event)
    this.clickHandler.emit($event);
    if(this.href !== null)this.router.navigate([this.href]);
  }

  ngOnInit(){
    // setting in this format inc ase we need to have mutltiple classess added in certain cases...
    if(this.level === "tertiary" && this.classes.indexOf("p-button-tertiary") === -1){
      this.classes.push("p-button-tertiary");
    }
    if(this.iconColor === "teal-light" && this.classes.indexOf("p-button-icon-teal-light") === -1){
      this.classes.push("p-button-icon-teal-light");
    }
    if(this.iconColor === "teal-medium" && this.classes.indexOf("p-button-icon-teal-medium") === -1){
      this.classes.push("p-button-icon-teal-medium");
    }
    if(this.iconColor === "gray-light" && this.classes.indexOf("p-button-icon-gray-light") === -1){
      this.classes.push("p-button-icon-gray-light");
    }
    if(this.iconColor === "red-light" && this.classes.indexOf("p-button-icon-red-light") === -1){
      this.classes.push("p-button-icon-red-light");
    }
    if(this.iconColor === "redwood-light" && this.classes.indexOf("p-button-icon-redwood-light") === -1){
      this.classes.push("p-button-icon-redwood-light");
    }
    if(this.iconColor === "teal-dark" && this.classes.indexOf("p-button-icon-teal-dark") === -1){
      this.classes.push("p-button-icon-teal-dark");
    }
    if(this.iconColor === "gray-dark" && this.classes.indexOf("p-button-icon-gray-dark") === -1){
      this.classes.push("p-button-icon-gray-dark");
    }
    this.fullClassString = " " + this.classes.join(" ") + " ";
    console.log(this.fullClassString)
  }
  
  getSeverity():"success" | "info" | "warning" | "danger" | "help" | "primary" | "secondary" | "contrast" | null | undefined{
    if(this.level === "primary" || this.level === "secondary")return this.level;
    if(this.level === "tertiary")this.classes.push("p-button-tertiary");
    this.level = null;
    return this.level;
  }

  getLabel():string | undefined{
    if(this.isIconOnly === true)return undefined;
    return this.label;
  }

  getIsDisabled(){
    return this.disabled;
  }

  getIcon(name:string|null|undefined){
    return name ? icomImages.get(name) : undefined ;
  }

}
