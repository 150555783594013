
import { Injectable } from "@angular/core";
import { DomHandler } from 'primeng/dom';
import { cleanSVGForOutput } from "../util/util";
import { SearchPageComponent } from "../page/search/search.page.component";
// import { adminRoutes, apexRoutes, vortexFECRoutes, vortexJFCRoutes, vortexLiteRoutes, vortexRoutes } from "../app.routes";
import { expand } from "rxjs";
import { UserModel } from "./MainModel";
import { MenuItem } from "primeng/api";
import App from "./App";


/**
 * The real label for navigation elements is taken from the back end
 * We just we need a user friendly key to match to.
 */
export const Vortex_Navigation:Array<any> = [
    {
        label: 'Campaign Dashboard',
        icon: "fa-sharp fa-light fa-house-flag",
        featureIds: [1],
        route: "vortex",  
    },
    {
        label: 'Donors',
        icon: "fa-sharp fa-light fa-users",
        featureIds: [2],
        items: [
        {
            label:"Donor Search",
            pageId: 7,
            featureIds: [],
            route:"vortex/donors"    
        },
        {
            label: "Add Donor",
            pageId: 9,
            featureIds: [],
            route:"vortex/donors/add"
        },
        {
            label: "Bulk Update",
            pageId: 45,
            featureIds: [7],
            route:"vortex/donors/update"  
        },
        {
            label: "Manual Dedupes",
            pageId: 13,
            featureIds: [3],
            route:"vortex/donors/dedupe"
        },
        {
            label: "Donor Flags",
            pageId: 15,
            featureIds: [4],
            route:"vortex/donors/flags"
        },
        {
            label: "Donor Call Sheets",
            pageId: 18,
            featureIds: [5],
            route:"vortex/donors/call-sheets"
        },

        ]
    },
    {
        label: 'Donations',
        icon: "fa-sharp fa-light fa-money-check-dollar-pen",
        featureIds: [2],
        items: [
            {
            label:"Search",
            pageId: 10,
            featureIds: [],
            route:"vortex/donations"
            },
            {
            label:"Add Donation",
            pageId: 19,
            featureIds: [],
            route:"vortex/donations/add"
            },
            {
            label:"Bulk Upload",
            pageId: 46,
            featureIds: [],
            route:"vortex/donations/upload"
            },
            {
            label:"Batches",
            pageId: 20,
            featureIds: [],
            route:"vortex/donations/batches"
            },
            {
            label:"Flags",
            pageId: 23,
            featureIds: [],
            route:"vortex/donations/flags"
            },
            {
            label:"Compliance Letters",
            pageId: 47,
            featureIds: [],
            route:"vortex/donations/compliance-letters"
            },
        ]
    },
    {
        label: 'Fundraising Activity',
        icon: "fa-sharp fa-light fa-calendar-days",
        featureIds: [10],
        items: [
        {
            label:"Fundraisers",
            pageId: 29,
            featureIds: [],
            route:"vortex/fundraisers"
        },
        {
            label:"Events",
            pageId: 26,
            featureIds: [],
            route:"vortex/fundraisers/events"
        },
        {
            label:"Initiatives",
            pageId: 32,
            featureIds: [],
            route:"vortex/fundraisers/initiatives"
        }
        ]
    },
    {
        label: 'Expenses',
        icon: "fa-sharp fa-light fa-chart-pie-simple-circle-dollar",
        featureIds: [12],
        pageId: 35,
        route:"vortex/expenses"
    },
    {
        label: 'Reporting Center',
        route: "vortex/reporting-center",
        featureIds: [14],
        pageId: 38,
        icon: "fa-sharp fa-light fa-chart-tree-map"
    },
    {
        label: 'FEC',
        icon: "fa-sharp fa-light fa-landmark",
        featureIds: [15],
        pageId: 38,
        items: [
            {
            label:"Vortex Filer",
            pageId: 39,
            featureIds: [],
            route:"vortex/fec/vortex"
            },
            {
            label:"Settings",
            pageId: 39,
            featureIds: [],
            route:"vortex/fec/settings"
            },
            {
            label:"Report Cleanup",
            pageId: 39,
            featureIds: [],
            route:"vortex/fec/report-cleanup"
            }
        ]
    },
    {
        label: 'JFC',
        icon: "fa-sharp fa-light fa-split",
        featureIds: [16],
        items: [
            {
            label:"Allocations",
            pageId: 40,
            featureIds: [],
            route:"vortex/jfc/allocations"
            },
            {
            label:"Distribution Batches",
            pageId: 40,
            featureIds: [],
            route:"vortex/jfc/batches"
            }
        ]
    }
];
    
export const Admin_Tools: Array<any> = [
        {
            label:"Campaign Settings",
            icon: "fa-sharp fa-light fa-megaphone",
            route:"/admin/campaign-settings/campaigns",
            items: [
                {
                label:"Campaign List",
                featureId: -1,
                route:"/admin/campaign-settings"
                },
                {
                label:"Fund Codes",
                featureId: -1,
                route:"/admin/campaign-settings/fund-codes"
                },
                {
                label:"Donation Source Rates",
                featureId: -1,
                route:"/admin/campaign-settings/donation-source-rates"
                },
                {
                label:"Accounting System",
                featureId: -1,
                route:"/admin/campaign-settings/accounting-system"
                },
                {
                label:"Compliance",
                featureId: -1,
                route:"/admin/campaign-settings/compliance"
                },
                {
                label:"Search Throttle",
                featureId: -1,
                route:"/admin/campaign-settings/search"
                },
                {
                label:"Donor Campaign Limits",
                featureId: -1,
                route:"/admin/campaign-settings/donor-limits"
                },
                {
                label:"Campaign Types",
                featureId: -1,
                route:"/admin/campaign-settings/campaign-types"
                },
                {
                label:"Default Approver",
                featureId: -1,
                route:"/admin/campaign-settings/approver"
                },
                {
                label:"Power BI",
                featureId: -1,
                route:"/admin/campaign-settings/reporting"
                }
            ]
        },
        {
            label:"Organization Settings",
            icon: "fa-sharp fa-light fa-landmark",
            featureId: -1,
            route:"/donors/dedupes"
        },
        {
            label:"Users",
            icon: "fa-sharp fa-light fa-users-gear",
            items: [
            {
                label:"Invite New User",
                featureId: -1,
                route:"/admin/users/invite-new-user"
            },
            {
                label:"All Users",
                featureId: -1,
                route:"/admin/users/all-users",
            },
            {
                label:"User Bulk Upload",
                featureId: -1,
                route:"/admin/users/user-bulk-upload"
            }
            ]
        },
        {
            label:"System Errors",
            icon: "fa-sharp fa-light fa-message-exclamation",
            items: [
            {
                label:"Login errors",
                featureId: -1,
                route:"/admin/system-errors/login-errors",
            },
            {
                label:"Logout errors",
                featureId: -1,
                route:"/admin/system-errors/logout-errors",
            },
            {
                label:"Transaction Errors",
                featureId: -1,
                route:"/admin/system-errors/transaction-errors"
            }
            ]
        },
];

export const Anonymous_Navigation:Array<any> = [
    {   
        label: 'Select Campaign',
        icon: "fa-sharp fa-light fa-thumbtack",
        featureId: -1,
        pageId: -1,
        route: "campaign-selection",    
    },
];

export const APEX: Array<any>= [
    {
        label: 'APEX',
        expanded: true,
        icon: "fa-sharp fa-light fa-flag-checkered",
        featureIds: [20],
        items: [
        {
            label:"My Widgets",
            pageId: 41,
            featureIds: [19],
            route:"/apex/donors/dedupes",
            
        },
        {
            label:"Settings",
            pageId: 42,
            featureIds: [20],
            route:"/apex/donors/flags"
        }
        ]
    },
];

export const Vortex_Lite: Array<any> = [
    {
        label:"My Fundraising Tally",
        featureIds: [17],
        pageId: 43,
        route:"vortex-lite/fundraising-tally",
    },
    {
        label:"Events",
        featureIds: [9],
        pageId: 26,
        route:"vortex-lite/events",
    },
    {
        label:"Fundraisers",
        featureIds: [10],
        pageId: 29,
        route:"vortex-lite/fundraisers",
    },
    {
        label:"Donor Flags",
        featureIds: [4],
        pageId: 15,
        route:"vortex-lite/donor-flags",
    },
    {
        label:"Club Settings",
        featureIds: [18],
        pageId: 44,
        route:"vortex-lite/club-settings",
    }   
]

/**
 * These are pages that arent in the navigation but we still need to map 
 * the route to a feature and page 
 */
export const NonNavigationAccessibity = [
    
    {
        label:"Donor Card",
        featureId: 2,
        pageId: 8,
        route:"vortex/donors",
        showOnNav: false
    },
    {
        label:"Donation Card",
        featureId: 2,
        pageId: 11,
        route: "",
        showOnNav: false
    },
    {
        label:"Edit Donation",
        featureId: 2,
        pageId: 12,
        route: "",
        showOnNav: false
    },
    {
        label:"Donor Bulk Update",
        featureId: 2,
        pageId: 45,
        route: "",
        showOnNav: false
    },
    {
        label:"Compare Donors panel",
        featureId: 3,
        pageId: 14,
        route: "",
        showOnNav: false
    },
    {
        label:"Add Donor Flag",
        featureId: 4,
        pageId: 16,
        route: "",
        showOnNav: false
    },
    {
        label:"Edit Donation Flag",
        featureId: 8,
        pageId: 25,
        route: "",
        showOnNav: false
    },
    {
        label:"Edit Donor Flag",
        featureId: 4,
        pageId: 17,
        route: "",
        showOnNav: false
    },
    {
        label:"Batch Details/Edit Batch",
        featureId: 7,
        pageId: 22,
        route: "",
        showOnNav: false
    },
    {
        label:"Edit Event",
        featureId: 9,
        pageId: 28,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
]

export const NavSetsByAppName:Map<string, Array<any>> = new Map();
NavSetsByAppName.set("Vortex3",Vortex_Navigation);
NavSetsByAppName.set("VortexLite", (Vortex_Lite));
NavSetsByAppName.set("Apex", (APEX));
NavSetsByAppName.set("Admin Tools", Admin_Tools);

export const NavOrder = [
    {
        label:"vortex3",
        page:"campaign-dashboard"
    },
    {
        label:"vortexlite",
        page:"campaign-dashboard"
    },
    {
        label:"apex",
        page:"campaign-dashboard"
    },
    {
        label:"admin tools", // admins have access to everything...?
        page:"campaign-dashboard"
    }
]

export default class NavModel {
    document: any;
    applicationNavset:Array<any> = [];
    currentState:"closed"|"open" = "closed";

    // TODO: move somewhere out of the model
    toggleMainNavState:Function = () => {
        this.currentState = this.currentState === "open" ? "closed" : "open" ;

        if (this.currentState === "open") {
            DomHandler.addClass(this.document.body, 'p-overflow-hidden');
        };

        if (this.currentState === "closed") {
            DomHandler.removeClass(this.document.body, 'p-overflow-hidden');
        };
    };
    isShowBreadcrumb:boolean = !false;
}