import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
  private donorSelected = new BehaviorSubject<any>(null);
  private addNewDonor = new BehaviorSubject<any>(null);
  private closeNewDonor = new BehaviorSubject<any>(null);
  private saveDonor = new BehaviorSubject<any>(null);
  donorSelected$ = this.donorSelected.asObservable();
  addNewDonor$ = this.addNewDonor.asObservable();
  closeNewDonor$ = this.closeNewDonor.asObservable();
  saveDonor$ = this.saveDonor.asObservable();
    constructor(
      private http: HttpClient) {
  }

  emitDonorSelected(selectedDonor: any) {
      this.donorSelected.next(selectedDonor);
  }

  emitSaveDonor(donor: any) {
    this.saveDonor.next(donor);
  }

  emitAddNewDonorSelected() {
    this.addNewDonor.next('test');
  }

  emitCloseDonorModal() {
    this.closeNewDonor.next('test');
  }



  client!: HttpClient;
  getRawData(dataType: string, params: { campaignId: number; sort: string; order: string; limit: string; offset: string; searchFilters: string; }) {
      var url = "";
      switch (dataType) {
          case 'donors':
              url = `/api/donor/donor-search`
              break;
          case 'donations':
              url = '/api/donation/donation-columns'
              break
          case 'expenses':
              url = '/api/expense/expense-columns'
              break
          default:
              break;
      }
      return this.http.get<any>(url, { params, withCredentials: true });
  }
}
