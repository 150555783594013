import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../global/config/app.config';
import { APP_CONFIG } from '../../global/config/config.token';
import { Router, RouterConfigOptions } from '@angular/router';
import { DonorFlags, DonorTypes,RelationshipTypes, States, AddressTypes, DonorFormModel, DonorFlag } from '../models/DonorModel';


@Injectable({
  providedIn: 'root'
})

export class DonorAddService {
  constructor(private httpClient: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig, private router: Router) { }

  getDonorTypes = (campaignId: number ): Observable<Array<DonorTypes>> => {
      return this.httpClient.get<DonorTypes[]>(`${this.config.apiUrl}donor/donor-types?campaignId=${campaignId}`, { withCredentials: true });
    }

  getDonorFlags = (campaignId: number ): Observable<Array<DonorFlags>> => {
    return this.httpClient.get<DonorFlags[]>(`${this.config.apiUrl}Lookups/DonorFlags?campaignId=${campaignId}`, { withCredentials: true });
  }
  getStates = (campaignId: number ): Observable<Array<States>> => {
    return this.httpClient.get<States[]>(`${this.config.apiUrl}Lookups/states?campaignId=${campaignId}`, { withCredentials: true });
  }
  getAddressTypes = (campaignId: number ): Observable<Array<AddressTypes>> => {
    return this.httpClient.get<AddressTypes[]>(`${this.config.apiUrl}Lookups/AddressTypes?campaignId=${campaignId}`, { withCredentials: true });
  }
  getPhoneTypes = (campaignId: number ): Observable<Array<RelationshipTypes>> => {
    return this.httpClient.get<RelationshipTypes[]>(`${this.config.apiUrl}Lookups/PhoneTypes?campaignId=${campaignId}`, { withCredentials: true });
  }
  
  getEmailTypes = (campaignId: number ): Observable<Array<RelationshipTypes>> => {
    return this.httpClient.get<RelationshipTypes[]>(`${this.config.apiUrl}Lookups/EmailTypes?campaignId=${campaignId}`, { withCredentials: true });
  }

  getRelationTypes = (campaignId: number ): Observable<Array<RelationshipTypes>> => {
    return this.httpClient.get<RelationshipTypes[]>(`${this.config.apiUrl}donor/donor-relationTypes?campaignId=${campaignId}`, { withCredentials: true });
  }

  searchByFirstName = (query: string, campaignId: number ): Observable<Array<RelationshipTypes>> => {
    return this.httpClient.get<any[]>(`${this.config.apiUrl}donor/donor-search-name?searchFnString=${query}&campaignId=${campaignId}&searchBy=1`, { withCredentials: true });
  }
 
  searchByLastName = (query: string, campaignId: number ): Observable<Array<RelationshipTypes>> => {
    return this.httpClient.get<any[]>(`${this.config.apiUrl}donor/donor-search-name?searchLnString=${query}&campaignId=${campaignId}&searchBy=2`, { withCredentials: true });
  }

  saveDonor = (donorFormModel: DonorFormModel): Observable<any> => {
    return this.httpClient.post(`${this.config.apiUrl}donor/Adddonor`, donorFormModel, { withCredentials: true });
  }

  addDonorFlag = (donorFlag: DonorFlag, campaignId: number): Observable<any> => {
    const url = `${this.config.apiUrl}donor/donor-flags?campaignId=${campaignId}`; 
    return this.httpClient.post(url, donorFlag, { withCredentials: true });
  }

  addRelationType = (relationshipName: string, campaignId: number): Observable<any> => {
    const url = `${this.config.apiUrl}donor/donor-relationTypes?relationshipName=${encodeURIComponent(relationshipName)}&campaignId=${campaignId}`;
    return this.httpClient.post(url, {}, { withCredentials: true });
  }
}
