
<p-button 
    title={{label}}
    styleClass={{fullClassString}}
    (onClick)="clicked($event)"
    [label]="getLabel()"
    icon={{getIcon(iconImg)}}
    [severity]="getSeverity()"
    round="round"
    [size]="size"
    [outlined]="borderStyle === 'outline'"
    [text]="borderStyle === 'text-only' || borderStyle === 'link'"
    [link]="borderStyle === 'link'"
    [disabled]="getIsDisabled()" 
    />  