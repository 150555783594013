import { Component, OnInit, ViewChild, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { Stepper, StepperModule } from 'primeng/stepper';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'primeng/accordion';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DonorAddService } from '../../services/donor-add.service';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MenuModule } from 'primeng/menu';
import {
  DonorTypes,
  AddressTypes,
  States,
  RelationshipTypes,
  DonorFlags,
  DonorFlag,
  DonorFormModel,
  Address,
  Email,
  PhoneNumber,
  RelationshipType,
  EmailTypes,
  PhoneTypes,
  Person
} from '../../models/DonorModel';
import { AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ListboxModule } from 'primeng/listbox';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { GenericButtonComponent } from "../../../global/component/generic/generic-button/generic-button.component";
import { ToggleButtonModule } from 'primeng/togglebutton';
import { MainModel } from "../../../global/model/MainModel";
import { debounceTime, switchMap } from 'rxjs';
import { GenericTextInputComponent } from '../../../global/component/generic/generic-text-input/generic-text-input.component';
import { SearchService } from '../../../global/services/data/SearchService/searchservice';

@Component({
  selector: 'app-donor-add',
  standalone: true,
  templateUrl: './donor-add.component.html',
  styleUrls: ['./donor-add.component.scss'],
  imports: [
    MenuModule,
    CommonModule,
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    InputTextModule,
    MultiSelectModule,
    StepperModule,
    ButtonModule,
    InputSwitchModule,
    ToastModule,
    ConfirmDialogModule,
    CheckboxModule,
    RadioButtonModule,
    DropdownModule,
    ProgressSpinnerModule,
    CalendarModule,
    AutoCompleteModule,
    DialogModule,
    TableModule,
    ListboxModule,
    TabViewModule,
    MessagesModule,
    ToggleButtonModule,
    GenericButtonComponent,
    GenericTextInputComponent
  ],
  providers: [ConfirmationService, MessageService, DonorAddService],
})
export class DonorAddComponent {
  @Input() isModalPopup: boolean = false;
  form: FormGroup;
  editAddressForm: FormGroup;
  editRelationshipTypesForm: FormGroup;
  editPhoneNumberForm: FormGroup;
  editEmailForm: FormGroup;
  editSaveDonorFlag: FormGroup;
  editSaveRelationshipType: FormGroup;
  checked: boolean = true;
  unchecked: boolean = false;
  isEmployed: string = 'yes';
  showDialog: boolean = false;
  duplicateRelationshipType: boolean = false;
  editAddressDialog: boolean = false;
  editRelationshipTypeDialog: boolean = false;
  editEmailDialog: boolean = false;
  editDialog: boolean = false;
  edititem: any | null = null;
  editIndex: number | null = null;

  // editAddressDialog: boolean = false;
  // editRelationshipTypeDialog: boolean = false;
  // editEmailDialog: boolean = false;

  dialogType: string | null = null;

  employmentStatuses = [
    { name: 'Retired', value: 'Retired' },
    { name: 'Student', value: 'Student' },
    { name: 'Unemployed', value: 'Unemployed' },
  ];

  campaignId: any | null;
  emailTypes: EmailTypes[] = [];
  phoneTypes: PhoneTypes[] = [];
  filteredFirstNames: Person[] = [];
  filteredLastNames: Person[] = [];
  filteredDialogFirstNames: Person[] = [];
  filteredDialogLastNames: Person[] = [];
  donorTypes: DonorTypes[] = [];
  addressTypes: AddressTypes[] = [];
  states: States[] = [];
  relationshipTypeOptions: RelationshipTypes[] = [];
  donorFlagOptions: DonorFlags[] = [];
  relationshipType: string = '';
  savedAddresses: Address[] = [];
  savedPhoneNumbers: PhoneNumber[] = [];
  savedEmails: Email[] = [];
  savedDonorFlags: DonorFlags[] = [];
  filteredDonorFlags: DonorFlags[] = [];
  savedRelationships: RelationshipType[] = [];

  constructor(private fb: FormBuilder,
     private donorAddService: DonorAddService,
     private searchService: SearchService,
     protected mainModel: MainModel, 
     private confirmationService: ConfirmationService,
     private messageService: MessageService) {
    this.form = this.fb.group({
      donorTypeId: [''],
      prefix: ['', [Validators.pattern('^[a-zA-Z]+$')]],
      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
      middleName: ['', [Validators.pattern('^[a-zA-Z]+$')]],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
      suffix: [''],
      currentlyEmployed: ['yes'],
      employeeType: [''],
      employmentStatus: [''],
      employer: [''],
      occupation: [''],

      donorId: [{ value: '', disabled: true }],
      legacyId: ['', Validators.pattern('^[0-9]+$')],
      fECCommitteeID: [''],
      organization: [''],
      dateOfBirth: [''],
      candidateId: [''],

      //Contacts
      address: this.fb.group({
        addressTypeId: [''],
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        stateId: [''],
        zipcode: [''],
        county: [''],
        isPrimary: [false]
      }),

      phoneNumber: this.fb.group({
        phoneTypeId: [''],
        phoneNumber: ['', [Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/)]]
        // phoneNumber: ['', [
        //   Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/), 
        //   Validators.required, Validators.maxLength(14)
        // ]]
      }),

      email: this.fb.group({
        emailTypeId: [''],
        email: ['', [Validators.email]]
      }),

      //Relationships
      relationshipTypes: this.fb.group({
        relationshipTypeId: [''],
        firstName: [''],
        lastName: [''],
        sameHouseHold: [true],
      }),

      informalName: [''],
      formalSalutation: [''],
      mailSalutation: [''],

      //Additional details
      donorFlagType: [''],
      doNotCall: [false],
      doNotEmail: [false],
      doNotMail: [false],
      doNotSolicit: [false],
      isDeceased: [false],
    });

    this.editSaveDonorFlag = this.fb.group({
      title: ['', Validators.required],
      year: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.editSaveRelationshipType = this.fb.group({
      relationshipType: ['', Validators.required],
    });

    this.editAddressForm = this.fb.group({
      addressTypeId: [''],
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      stateId: [''],
      zipcode: [''],
      county: [''],
      isPrimary: [false]
    });

    this.editPhoneNumberForm = this.fb.group({
      phoneTypeId: [''],
      phoneNumber: ['', Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/)]
    });

    this.editEmailForm = this.fb.group({
      emailTypeId: [''],
      email: ['', [Validators.email]]
    });

    this.editRelationshipTypesForm = this.fb.group({
      relationshipTypeId: [''],
      firstName: [''],
      lastName: [''],
      sameHouseHold: [true],
    });

    this.campaignId = 81;
    //#region "Bindings"

    this.form.get('donorTypeId')?.valueChanges.subscribe((value) => {

      if (value === '7') {
        this.form.get('fECCommitteeID')?.setValue('');
        this.form.get('organization')?.setValue('');
      }
      else {
        this.form.get('candidateId')?.setValue('');
        this.form.get('dateOfBirth')?.setValue('');
      }
    });

    this.form.get('currentlyEmployed')?.valueChanges.subscribe((value) => {
      if (value === 'yes') {
        this.form.get('employmentStatus')?.setValue('');
      }
      else if (value === 'no') {
        this.form.get('employer')?.setValue('');
        this.form.get('occupation')?.setValue('');
      }
      this.isEmployed = value;
    });

    this.donorAddService.getDonorTypes(parseInt(this.campaignId)).subscribe((data: any) => {
      this.donorTypes = data;
    });

    this.donorAddService.getStates(parseInt(this.campaignId)).subscribe((data: any) => {
      this.states = data;
    });

    this.donorAddService.getPhoneTypes(parseInt(this.campaignId)).subscribe((data: any) => {
      this.phoneTypes = data;
    });
    this.donorAddService.getEmailTypes(parseInt(this.campaignId)).subscribe((data: any) => {
      this.emailTypes = data;
    });
    this.donorAddService.getAddressTypes(parseInt(this.campaignId)).subscribe((data: any) => {
      this.addressTypes = data;
    });

    this.donorAddService.getRelationTypes(parseInt(this.campaignId)).subscribe((data: any) => {
      this.relationshipTypeOptions = data;
    });

    this.donorAddService.getDonorFlags(parseInt(this.campaignId)).subscribe((data: any) => {
      this.donorFlagOptions = data;
    });

    //#endregion "Bindings"
  }

  ngOnInit() {
    this.form.get('relationshipTypes.firstName')?.valueChanges
      .pipe(debounceTime(300), switchMap((q) => {
        if (q && q.length >= 3) {
          return this.donorAddService.searchByFirstName(q, this.campaignId)
        }
        return [];
      }))
      .subscribe((data: Person[]) => { this.filteredFirstNames = data; });

    this.form.get('relationshipTypes.lastName')?.valueChanges
      .pipe(debounceTime(300), switchMap((q) => {
        if (q && q.length >= 3) {
          return this.donorAddService.searchByLastName(q, this.campaignId)
        }
        return [];
      }))
      .subscribe((data: Person[]) => { this.filteredLastNames = data; });

    this.editRelationshipTypesForm.get('firstName')?.valueChanges
      .pipe(debounceTime(300), switchMap((q) => {
        if (q && q.length >= 3) {
          return this.donorAddService.searchByFirstName(q, this.campaignId)
        }
        return [];
      }))
      .subscribe((data: Person[]) => { this.filteredDialogFirstNames = data; console.log("Filtered First Names:", this.filteredDialogFirstNames); });

    this.editRelationshipTypesForm.get('lastName')?.valueChanges
      .pipe(debounceTime(300), switchMap((q) => {
        if (q && q.length >= 3) {
          return this.donorAddService.searchByLastName(q, this.campaignId)
        }
        return [];
      }))
      .subscribe((data: Person[]) => { this.filteredDialogLastNames = data; console.log("Filtered First Names:", this.filteredDialogLastNames); });
  }

  //#region "address"

  isAddAddressEnabled(): boolean {
    const addressGroup = this.form.get('address') as FormGroup;

    const addressTypeId = addressGroup.get('addressTypeId')?.value;
    const addressLine1 = addressGroup.get('addressLine1')?.value?.trim();
    const addressLine2 = addressGroup.get('addressLine2')?.value?.trim();
    const city = addressGroup.get('city')?.value?.trim();
    const stateId = addressGroup.get('stateId')?.value;
    const zipcode = addressGroup.get('zipcode')?.value?.trim();
    const county = addressGroup.get('county')?.value?.trim();

    return !!addressTypeId && (!!addressLine1 || !!addressLine2 || !!stateId || !!city || !!county || !!zipcode);
  }

  addAddress(): void {
    const addr = this.form.get('address')?.value;
    if (addr.isPrimary) {
      this.savedAddresses.forEach((addr) => (addr.isPrimary = false));
    }
    const address: Address = {
      addressTypeId: addr.addressTypeId || '',
      addressLine1: addr.addressLine1 || '',
      addressLine2: addr.addressLine2 || '',
      city: addr.city || '',
      stateId: addr.stateId || '',
      zipcode: addr.zipcode || '',
      county: addr.county || '',
      isPrimary: addr.isPrimary || false
    }
    this.savedAddresses.push(address);
    this.clearAddress();
  }

  clearAddress() {
    this.form.get('address')?.reset();
  }

  editAddress(address: Address): void {
    this.dialogType = 'address';
    this.edititem = { ...address };
    this.editIndex = this.savedAddresses.findIndex(x => x == address);
    this.editAddressForm.patchValue(this.edititem);
    this.editDialog = true;
  }

  deleteAddress(address: Address): void {
    this.savedAddresses = this.savedAddresses.filter((addr) => addr !== address);
  }

  getAddressTypeValue(key: string): string {
    const addressTypeId = this.addressTypes.find(o => o.key === key);
    return addressTypeId ? addressTypeId.value : '';
  }

  getStateValue(key: string): string {
    const stateId = this.states.find(o => o.key === key);
    return stateId ? stateId.value : '';
  }

  getFormattedAddress(address: Address): string {
    return [
      `<strong>${this.getAddressTypeValue(address.addressTypeId)} ${address.isPrimary ? '- Primary' : ''} </strong>`,
      [address.addressLine1, address.addressLine2].filter(Boolean).join(', '),
      [address.city, address.stateId ? this.getStateValue(address.stateId) : '', address.county, address.zipcode].filter(Boolean).join(', ')
    ].filter(Boolean).join('<br>');
  }
  //#endregion "Address"

  //#region "PhoneNumber"
  isAddPhoneNumberEnabled(): boolean {
    const numberGroup = this.form.get('phoneNumber') as FormGroup;
    const phoneTypeId = numberGroup.get('phoneTypeId')?.value;
    const phoneNumber = numberGroup.get('phoneNumber')?.value?.trim();
    const isNumberValid = numberGroup.get('phoneNumber')?.valid || phoneNumber === '';

    return !!phoneTypeId && !!phoneNumber && isNumberValid;
  }

  addPhoneNumber(): void {
    const numberGroup = this.form.get('phoneNumber') as FormGroup;
    const phoneNumber: PhoneNumber = {
      phoneTypeId: numberGroup.get('phoneTypeId')?.value || '',
      phoneNumber: numberGroup.get('phoneNumber')?.value || '',
    }
    this.savedPhoneNumbers.push(phoneNumber);
    numberGroup.reset();
  }

  clearPhoneNumber() {
    this.form.get('phoneNumber')?.reset();
  }

  editPhoneNumber(phoneNumber: PhoneNumber): void {
    this.dialogType = 'phoneNumber';
    this.edititem = { ...phoneNumber };
    this.editIndex = this.savedPhoneNumbers.findIndex(x => x == phoneNumber);
    this.editPhoneNumberForm.patchValue(this.edititem);
    this.editDialog = true;
  }

  deletePhoneNumber(phoneNumber: PhoneNumber): void {
    this.savedPhoneNumbers = this.savedPhoneNumbers.filter((n) => n !== phoneNumber);
  }

  getPhoneTypeValue(key: string): string {
    const phoneTypeId = this.phoneTypes.find(o => o.key === key);
    return phoneTypeId ? phoneTypeId.value : '';
  }

  getFormattedPhoneNumber(phoneNumber: PhoneNumber): string {
    return ` <strong>${this.getPhoneTypeValue(phoneNumber.phoneTypeId)}</strong><br>
                     ${phoneNumber.phoneNumber}`
  }
  //#endregion "PhoneNumber"

  //#region "Email"
  disableAddEmail(): boolean {
    const emailGroup = this.form.get('email') as FormGroup;
    const emailTypeId = emailGroup.get('emailTypeId')?.value;
    const email = emailGroup.get('email')?.value?.trim();
    const isEmailValid = emailGroup.get('email')?.valid || email === '';

    return !!emailTypeId && !!email && isEmailValid;
  }

  addEmail(): void {
    const emailGroup = this.form.get('email') as FormGroup;
    const email: Email = {
      emailTypeId: emailGroup.get('emailTypeId')?.value || '',
      email: emailGroup.get('email')?.value || '',
    }
    this.savedEmails.push(email);
    emailGroup.reset();
  }

  clearEmail() {
    this.form.get('email')?.reset();
  }

  editEmail(email: Email): void {
    this.dialogType = 'email';
    this.edititem = { ...email };
    this.editIndex = this.savedEmails.findIndex(x => x == email);
    this.editEmailForm.patchValue(this.edititem);
    this.editDialog = true;
  }

  deleteEmail(email: Email): void {
    this.savedEmails = this.savedEmails.filter((n) => n !== email);
  }

  getEmailTypeValue(key: string): string {
    const emailTypeId = this.emailTypes.find(o => o.key === key);
    return emailTypeId ? emailTypeId.value : '';
  }

  getFormattedEmail(email: Email): string {
    return ` <strong>${this.getEmailTypeValue(email.emailTypeId)}</strong><br>
                     ${email.email}`
  }

  //#endregion "Email"

  //#region "Validations"
  allowOnlyNumbers(e: KeyboardEvent): void {
    const charCode = e.key.charCodeAt(0);
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  }

  formatPhoneNumber(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '');

    if (value.length > 10) {
      value = value.substring(0, 10);
    }
    if (value.length >= 7) {
      input.value = `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6)}`;
    } else if (value.length >= 4) {
      input.value = `(${value.substring(0, 3)}) ${value.substring(3)}`;
    } else if (value.length > 0) {
      input.value = `(${value}`;
    }
    this.form.controls['phoneNumber'].setValue(input.value);
  }
  //#endregion "Validations"

  //#region "RelationshipType"
  disableAddRelationship() {
    const relationshipTypesGroup = this.form.get('relationshipTypes') as FormGroup;
    const relationshipTypeId = relationshipTypesGroup.get('relationshipTypeId')?.value?.trim();
    const firstName = relationshipTypesGroup.get('firstName')?.value?.trim();
    const lastName = relationshipTypesGroup.get('lastName')?.value?.trim();

    return !!relationshipTypeId && !!firstName && lastName;
  }

  addRelationship(): void {
    const type = this.form.get('relationshipTypes')?.value;

    const relationshipType: RelationshipType = {
      relationshipTypeId: type.relationshipTypeId || '',
      firstName: type.firstName || '',
      lastName: type.lastName || '',
      sameHouseHold: type.sameHouseHold || false
    }
    this.savedRelationships.push(relationshipType);
    this.clearRelationship();
  }

  clearRelationship() {
    this.form.get('relationshipTypes')?.reset();
    this.form.get('relationshipTypes')?.markAsPristine();
    this.form.get('relationshipTypes')?.markAsUntouched();
  }

  editRT(relationshipType: RelationshipType): void {
    this.dialogType = 'relationshipType';
    this.edititem = { ...relationshipType };
    this.editIndex = this.savedRelationships.findIndex(x => x == relationshipType);
    this.editRelationshipTypesForm.patchValue(this.edititem);
    this.editDialog = true;
  }

  deleteRT(relationshipType: RelationshipType): void {
    this.savedRelationships = this.savedRelationships.filter((rt) => rt !== relationshipType);
  }

  getrelationshipTypeValue(value: string): string {
    const option = this.relationshipTypeOptions.find(o => o.value === value);
    const text = option ? option.text : null;
    return text || '';
  }

  getFormattedRT(relationshipType: RelationshipType): string {
    return ` <strong>${this.getrelationshipTypeValue(relationshipType.relationshipTypeId)}</strong>  <br>
    ${relationshipType.firstName} ${relationshipType.lastName}`
  }

  clearSaveRelationshipType() {
    this.editSaveRelationshipType.reset();
    this.showDialog = false;
  }

  saveNewRelationship() {

    this.duplicateRelationshipType= false;
    if (this.editSaveRelationshipType.valid) {
      this.relationshipType = this.editSaveRelationshipType.value.relationshipType || '';
      if (this.relationshipType) {

        if (this.relationshipTypeOptions.some(f => f.text === this.relationshipType.trim())) {
          this.duplicateRelationshipType= true;
        }
        else {
          this.donorAddService.addRelationType(this.relationshipType, this.campaignId).subscribe({
            next: (response) => {
              this.showDialog = false;
              this.relationshipType = '';

              this.donorAddService.getRelationTypes(this.campaignId).subscribe((data: any) => {
                this.relationshipTypeOptions = data;
              });
            },
            error: (error) => {
            },
          });
        }
      }
    }
  }

  onFirstNameSelect(event: AutoCompleteSelectEvent) {
    const selectedFN: Person = event.value;
    const fullName = selectedFN.text.split(' ');
    this.form.get('relationshipTypes.firstName')?.setValue(fullName[0]);
    this.form.get('relationshipTypes.lastName')?.setValue(fullName.slice(1).join(''));
  }
  onLastNameSelect(event: AutoCompleteSelectEvent) {
    const selectedLN: Person = event.value;
    const fullName = selectedLN.text.split(' ');
    this.form.get('relationshipTypes.firstName')?.setValue(fullName[0]);
    this.form.get('relationshipTypes.lastName')?.setValue(fullName.slice(1).join(''));
  }

  //edit pop up logic
  onDialogFirstNameSelect(event: AutoCompleteSelectEvent) {
    console.log('Selected First Name:', event);
    const selectedFN: Person = event.value;
    const fullName = selectedFN.text.split(' ');
    this.editRelationshipTypesForm.get('firstName')?.setValue(fullName[0]);
    this.editRelationshipTypesForm.get('lastName')?.setValue(fullName.slice(1).join(''));
  }

  onDialogLastNameSelect(event: AutoCompleteSelectEvent) {
    console.log('Selected last Name:', event);
    const selectedLN: Person = event.value;
    const fullName = selectedLN.text.split(' ');
    this.editRelationshipTypesForm.get('firstName')?.setValue(fullName[0]);
    this.editRelationshipTypesForm.get('lastName')?.setValue(fullName.slice(1).join(''));
  }
  //#endregion "RelationshipType"

  //#region "Donor flags"
  addDonorFlag(): void {
    const dfType = this.form.get('donorFlagType')?.value;
    if (dfType && this.donorFlagOptions.find((f) => f.key === dfType.key)) {
      this.savedDonorFlags.push({ ...dfType });
    }
    this.clearDonorFlag();
  }

  clearDonorFlag(): void {
    this.form.get('donorFlagType')?.reset();
  }

  deleteDonorFlag(donorFlag: DonorFlags): void {
    this.savedDonorFlags = this.savedDonorFlags.filter((d) => d.key !== donorFlag.key);
  }

  filterDonorFlags(event: any) {
    const query = event.query.toLowerCase();
    this.filteredDonorFlags = this.donorFlagOptions.filter((f) =>
      f.value.toLowerCase().includes(query)
    );
  }

  clearNewDonorFlagType() {
    this.editSaveDonorFlag.reset();
    this.showDialog = false;
  }

  saveNewDonorFlagType() {
    if (this.editSaveDonorFlag.valid) {

      const flag: DonorFlag = {
        year: parseInt(this.editSaveDonorFlag.value.year || '', 10),
        description: this.editSaveDonorFlag.value.description || '',
        title: this.editSaveDonorFlag.value.title || '',
      };

      this.donorAddService.addDonorFlag(flag, this.campaignId).subscribe({
        next: (response) => {
          this.showDialog = false;
          this.editSaveDonorFlag.reset();
          this.donorAddService.getDonorFlags(this.campaignId).subscribe((data: any) => {
            this.donorFlagOptions = data;
          });
        },
        error: (error) => {
          console.log('Failed to add Donor flag');
        },
      });
    }
  }

  //#endregion "donorFlag"

  closeDialog(): void {
    if (this.isModalPopup) {
      this.searchService.emitCloseDonorModal();
    } else {
      this.editDialog = false;
      this.edititem = null;
      this.dialogType = null;
      this.editIndex = null;
    }
  }
  disableSaveInEdit():boolean{

    var disableAddress= false;
    var disablePhoneNumber = false;
    var disableEmail = false; 
    var disableRelationship= false;
     
    if (this.dialogType === 'address') {
        const addressTypeId = this.editAddressForm.get('addressTypeId')?.value;
        const addressLine1 = this.editAddressForm.get('addressLine1')?.value?.trim();
        const addressLine2 = this.editAddressForm.get('addressLine2')?.value?.trim();
        const city = this.editAddressForm.get('city')?.value?.trim();
        const stateId = this.editAddressForm.get('stateId')?.value;
        const zipcode = this.editAddressForm.get('zipcode')?.value?.trim();
        const county = this.editAddressForm.get('county')?.value?.trim();
        disableAddress =!!addressTypeId && (!!addressLine1 || !!addressLine2 || !!stateId || !!city || !!county || !!zipcode);
    }
    else if (this.dialogType === 'phoneNumber') {
        const phoneTypeId = this.editPhoneNumberForm.get('phoneTypeId')?.value;
        const phoneNumber = this.editPhoneNumberForm.get('phoneNumber')?.value?.trim();
        const isNumberValid = this.editPhoneNumberForm.get('phoneNumber')?.valid || phoneNumber === '';
        disablePhoneNumber=  !!phoneTypeId && !!phoneNumber && isNumberValid;
    }
    else if (this.dialogType === 'email') {
        const emailTypeId = this.editEmailForm.get('emailTypeId')?.value;
        const email = this.editEmailForm.get('email')?.value?.trim();
        const isEmailValid = this.editEmailForm.get('email')?.valid || email === '';
        disableEmail = !!emailTypeId && !!email && isEmailValid;
    }
    else if (this.dialogType === 'relationshipType') {
        const relationshipTypeId = this.editRelationshipTypesForm.get('relationshipTypeId')?.value?.trim();
        const firstName = this.editRelationshipTypesForm.get('firstName')?.value?.trim();
        const lastName =  this.editRelationshipTypesForm.get('lastName')?.value?.trim();
        disableRelationship = !!relationshipTypeId && !!firstName && lastName;
    }
    
    var result=disableAddress || disablePhoneNumber || disableEmail || disableRelationship;;
    return  result;
  }

  saveEditItem(): void {

    if (this.dialogType === 'address') {
      if (this.editAddressForm.valid && this.editIndex != null) {
        this.savedAddresses[this.editIndex] = this.editAddressForm.value;
      }
    }

    else if (this.dialogType === 'phoneNumber') {
      if (this.editPhoneNumberForm.valid && this.editIndex != null) {
        this.savedPhoneNumbers[this.editIndex] = this.editPhoneNumberForm.value;
      }
    }

    else if (this.dialogType === 'email') {
      if (this.editEmailForm.valid && this.editIndex != null) {
        this.savedEmails[this.editIndex] = this.editEmailForm.value;
      }
    }

    else if (this.dialogType === 'relationshipType') {
      if (this.editRelationshipTypesForm.valid && this.editIndex != null) {
        this.savedRelationships[this.editIndex] = this.editRelationshipTypesForm.value;
      }
    }

    this.closeDialog();
  }

  getDialogTitle(dialogType: string | null): string {

    switch (dialogType) {
      case 'relationshipType':
        return 'Relationship Type';
      case 'email':
        return 'Email';
      case 'phoneNumber':
        return 'Phone Number';
      case 'address':
        return 'Address';
      default:
        return '';
    }
  }

  formValidation() {
    const donorTypeId = this.form.get('donorTypeId')?.value;
    const firstName = this.form.get('firstName')?.value?.trim();
    const lastName = this.form.get('lastName')?.value?.trim();
    const isDonorTypeIdValid = this.form.get('donorTypeId')?.valid || donorTypeId === '';
    const isFirstNameValid = this.form.get('firstName')?.valid || firstName === '';
    const isLastNameValid = this.form.get('lastName')?.valid || lastName === '';

    return !!donorTypeId && !!firstName && !!lastName && isDonorTypeIdValid && isFirstNameValid && isLastNameValid;
  }

  reset() {
    this.form.reset({
      donorTypeId: '',
      prefix: '',
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      currentlyEmployed: ['yes'],
      employeeType: '',
      employmentStatus: '',
      employer: '',
      occupation: '',
      fecCommitteeId: '',
      organization: '',
      donorId: '',
      legacyId: '',
      candidateId: '',
      dateOfBirth: '',
      addresses: [],
      phoneNumbers: [],
      emails: [],
      relationshipTypeOptions: [],
      informalName: '',
      formalSalutation: '',
      mailSalutation: '',
      donorFlagIds: [],
      doNotCall: false,
      doNotEmail: false,
      doNotMail: false,
      doNotSolicit: false,
      isDeceased: false,
    });
    this.savedAddresses = [];
    this.savedPhoneNumbers = [];
    this.savedEmails = [];
    this.savedDonorFlags = [];
    this.savedRelationships = [];
  }

  save(event: any) {
    if (this.form.invalid) {
      return;
    }

    const flags = this.savedDonorFlags.map((flag) => parseInt(flag.key, 10));

    const donorFormModel: DonorFormModel = {
      campaignId:this.campaignId,  //0,
      donorTypeId: this.form.value.donorTypeId || '',
      prefix: this.form.value.prefix || '',
      firstName: this.form.value.firstName || '',
      middleName: this.form.value.middleName || '',
      lastName: this.form.value.lastName || '',
      suffix: this.form.value.suffix || '',
      currentlyEmployed: this.form.value.currentlyEmployed === 'yes' ? true : false,
      employeeType: this.form.value.employeeType || '',
      employmentStatus: this.form.value.employmentStatus || '',
      employer: this.form.value.employer || '',
      occupation: this.form.value.occupation || '',
      fecCommitteeId: this.form.value.fECCommitteeID || '',
      organization: this.form.value.organization || '',
      donorId: this.form.value.donorId || '',
      legacyId: this.form.value.legacyId || '',
      candidateId: this.form.value.candidateId || '',
      dateOfBirth: this.form.value.dateOfBirth || '',

      addresses: this.savedAddresses,
      phoneNumbers: this.savedPhoneNumbers,
      emails: this.savedEmails,
      relationshipTypes: this.savedRelationships || '',
      informalName: this.form.value.informalName || '',
      formalSalutation: this.form.value.formalSalutation || '',
      mailSalutation: this.form.value.mailSalutation || '',

      donorFlagIds: flags,
      doNotCall: this.form.value.doNotCall || false,
      doNotEmail: this.form.value.doNotEmail || false,
      doNotMail: this.form.value.doNotMail || false,
      doNotSolicit: this.form.value.doNotSolicit || false,
      isDeceased: this.form.value.isDeceased || false,
    };

    console.log(donorFormModel);
    
   this.confirmationService.confirm({
          target: event.target as EventTarget,
          message: 'Are you sure you want to save this new donor?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          acceptIcon: "none",
          rejectIcon: "none",
          rejectButtonStyleClass: "p-button-text",
          accept: () => {
            this.donorAddService.saveDonor(donorFormModel).subscribe({
              next: (res) => {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'The donor has been saved successfully.' });
                this.reset();
                donorFormModel.donorId = res?.donorId;
        donorFormModel?.addresses?.forEach((a: any) => {
          a.state = this.states.find((o: any) => o.key === a.stateId)?.value ?? null;
        });
        this.searchService.emitSaveDonor(donorFormModel);
              },
              error: (error) => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to save donor information.\n Please review the details and try again!' });
              },
            });
          },
          reject: () => {
          }
        });
  }
}
