 
export default class App {
    constructor(){

    }

    id:number = -1;
    name:string = "";
    isAdminApp:Boolean = false;
    features:Array<any> = [];
    defaultPageUrl:string|null = null;
    defaultPagePath:Array<string> = [];

 }