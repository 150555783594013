import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

export enum SCREEN_PROFILE {
    XSMALL = 0,
    SMALL = 1,
    MEDIUM = 2,
    LARGE = 3,
    XLARGE = 4,
    XXLARGE = 5
}


 
@Injectable({
    providedIn: 'root'
  })
export class DisplayService {
    public screenHeight: number = 0;
    public screenWidth: number = 0;
    public profile:SCREEN_PROFILE = SCREEN_PROFILE.MEDIUM; //default when app starts??

    constructor(
        private router:Router, 
    ){
        router.events.subscribe((value:any) => {
            if(value instanceof NavigationEnd){
              this.onResize();
              }
        });
        this.onLoad();
    }

    onLoad(){
        this.apply();
    }

    onResize(){
        this.apply();
    }

    apply(){
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        this.setScreenProfile();
        console.debug(this.profile, this.screenHeight, this.screenWidth);
    }

     /**
      * $xs: 321.9px;
        $sm: 575.9px;
        $md: 767.9px;
        $lg: 991.9px;
        $xl: 1199.9px;
        $xxl: 1559.9px;
      */
     private setScreenProfile(){
         if(this.screenWidth <= 322){
             this.profile = SCREEN_PROFILE.XSMALL;
         }
         if(this.screenWidth <= 576){
             this.profile = SCREEN_PROFILE.SMALL;
         }
         if(this.screenWidth <= 768){
             this.profile = SCREEN_PROFILE.MEDIUM;
         }
         if(this.screenWidth <= 992){
             this.profile = SCREEN_PROFILE.LARGE;
         }
         if(this.screenWidth > 1200){
             this.profile = SCREEN_PROFILE.XLARGE;
         }
         if(this.screenWidth > 1560){
             this.profile = SCREEN_PROFILE.XXLARGE;
         }
     }
}