import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Table, TableModule, TablePageEvent } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { MultiSelectModule } from 'primeng/multiselect';
import { Observable } from 'rxjs/internal/Observable';
import { ToggleButtonModule } from 'primeng/togglebutton';
import PagingModel from '../../model/PagingMoel';
import { SearchModel, DisplayColumn, LookupItem } from '../../model/SearchModel';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { SearchService } from '../../services/data/SearchService/searchservice';
import { GenericButtonComponent } from "../../../global/component/generic/generic-button/generic-button.component";
import { ToastModule } from 'primeng/toast';
import { AddDonationService } from '../../../vortex/services/add-donation.service';

interface ExportColumn {
  title: string;
  dataKey: string;
}


/**
 * This component should take in a set of default colmns to show. 
 * These may be:
 * 1. Hard coded from the developer based on the page or user type
 * 2. injected from saved database preferences
 * 
 * Data Clumns:
 * Injected data fields should also match the columns passed in. 
 * 
 * 
 * Exported columns:
 * We may eventually want to control which fileds will be outut into omething like a print job - but for now we dont have a UI designed for this.
 */
@Component({
  selector: 'app-search',
  standalone: true,
  imports: [TableModule, CommonModule, ButtonModule, FormsModule, MultiSelectModule, ToggleButtonModule, MenuModule, GenericButtonComponent, ToastModule],
  providers: [MessageService, AddDonationService],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent implements OnInit {
  @ViewChild('dt')
  dt!: Table;
  dataObject!: Observable<unknown>;
  dataElements: Array<any> = [];
  isFullscreen: boolean = false;
  cols!: DisplayColumn[];
  exportColumns: ExportColumn[] = [];
  selectedStates: string[] = [];
  selectedFundCodes: number[] = [];
  defaultColumns: DisplayColumn[] = [];
  groupedOptions: any[] = [];
  selectedColumns: DisplayColumn[] = [];
  defaultdropdowns: SelectItem[] = [];
  defaultTextDropdowns: SelectItem[] = [];
  states: LookupItem[] = [];
  fundCodes: LookupItem[] = [];
  rawData: any[] = [];
  totalData: any[] = [];
  exportOptions: MenuItem[] =[];
  // expandIcon:any = PrimeIcons.EXPAND;

  filters: Array<string> = [];
  selectedRows: any[] = [];
  @Input() showInDialog: boolean = false;

  //inputs...
  @Input({ required: true }) pagingModel: PagingModel = new PagingModel();
  @Input({ required: true }) columnData: SearchModel = { displayColumns: [], states: [], fundCodes: [], dropDownOptions: [], textOptions: [] };

  // outputs
  @Output() dataViewUpdate = new EventEmitter<PagingModel>();

  constructor(
    private servicesearch: SearchService,
    private messageService: MessageService,
  ) {

  }

  resetFilters() {
    this.dt.clear();
    this.selectedStates = [];
    this.selectedFundCodes = [];
  }
  updatePagingModel(start: number, maxResults: number = 10) {
    const pModel: PagingModel = this.pagingModel;
    pModel.maxResults = maxResults;
    pModel.startRow = start;
    // we emit and the service call should inject a new model...
    this.dataViewUpdate.emit(pModel);
  }

  pageChange(event: TablePageEvent) {
    // this.first = ;
    // this.rows = event.rows;
    console.debug("Search:", event);
    this.updatePagingModel(event.first, event.rows);
  }

  ngOnInit() {
    this.exportOptions = [
      { label: 'Export to CSV', icon: 'pi pi-file', command: () => this.exportToCSV() },
      { label: 'Export to Excel', icon: 'pi pi-file-excel', command: () => this.exportToExcel() }
    ];
    this.initializeColumns();
  }

  ngOnChanges() {
    this.initializeColumns();
  }

  private initializeColumns() {
    this.defaultColumns = this.columnData && this.columnData.displayColumns ? this.columnData.displayColumns : [];
    if (this.defaultColumns)
      this.groupedOptions = this.groupColumnsBy('groupByColumn');
    this.selectedColumns = this.columnData && this.columnData.displayColumns ? this.columnData.displayColumns.filter(x => x.isDefault) : [];
    this.defaultdropdowns = this.columnData && this.columnData.dropDownOptions ? this.convertLookupItemsToSelectItems(this.columnData.dropDownOptions) : [];
    this.defaultTextDropdowns = this.columnData && this.columnData.textOptions ? this.convertLookupItemsToSelectItems(this.columnData.textOptions) : [];
    this.states = this.columnData && this.columnData.states || [];
    this.fundCodes = this.columnData && this.columnData.fundCodes || [];
    this.cols = this.selectedColumns || [];
    this.exportColumns = this.cols.map((col: any) => ({ title: col.header, dataKey: col.field }));
  }

  private convertLookupItemsToSelectItems(lookupItems: LookupItem[]): SelectItem[] {
    return lookupItems.map((item: LookupItem) => ({ value: item.key, label: item.value }));
  }

  groupColumnsBy(groupByField: keyof DisplayColumn) {
    const groups: any = {};
    this.defaultColumns.forEach(column => {
      const group = column[groupByField] as string;
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push( column ); // Pass entire DisplayColumn object as value
    });

    return Object.keys(groups).map(group => ({
      label: group,
      items: groups[group]
    }));
  }
  onGenerateReport() {
    // Collect the current filters from the p-table
    const filterData = this.buildFilterData(this.dt.filters);
    const sortField = this.dt.sortField?this.dt.sortField.toLowerCase() :"" ; // The column field being sorted
    const sortOrder = this.dt.sortOrder === 1 ? 'asc' : 'desc'; // 1 for ascending, -1 for descending
    const campaignId = 215; // Replace with your campaignId
    const limit = 10000; // Number of items per page (adjust if needed)
    const offset = 0; // Page offset (adjust if needed)
    // Make the API call to generate the report
    this.loadFilteredData(campaignId, sortField, sortOrder, limit, offset, filterData);
  }

  exportToCSV() {
    const dataToExport = this.dt.filteredValue ? this.dt.filteredValue : this.rawData;
    const csvData = this.convertToCSV(dataToExport);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.setAttribute('download', 'exported_data.csv');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  convertToCSV(data: any[]): string {
    if (!data || !data.length) return '';
  
    const headers = Object.keys(data[0]);
    const csvRows = [];
    csvRows.push(headers.join(','));
    for (const row of data) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '""');
        return `"${escaped}"`; 
      });
      csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
  }

  exportToExcel() {
    const dataToExport = this.dt.filteredValue ? this.dt.filteredValue : this.rawData;
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, 'data.xlsx');
  }

  // Function to build filter data from the table's filters
  private buildFilterData(filters: { [key: string]: any }): string {
    // Convert filter object to a JSON string or format it to fit your API requirements
    if (this.selectedStates && this.selectedStates.length > 0) {
      filters['states'] = {
        values: this.states
        .filter(state => this.selectedStates.includes(state.value))
        .map(state => state.key)
      };
    }
    else{
      filters['states']= { values: [] };
    }
    var selectedfunds = this.selectedColumns.filter(column => column.groupByColumn === "Fund Codes")
    if (selectedfunds && selectedfunds.length > 0) {
      filters['fundcodes'] = {
        values: this.selectedColumns.filter(column => column.groupByColumn === "Fund Codes").map(column => column.id)
      };
    }
    return JSON.stringify(filters);
  }

  // Function to make API call with filters
  private loadFilteredData(campaignId: number, sort: string, order: string, limit: number, offset: number, filterData: string) {
    const params = {
      campaignId: campaignId,
      sort: sort,
      order: order,
      limit: limit.toString(),
      offset: offset.toString(),
      searchFilters: filterData
    };
    this.rawData = [];
    this.servicesearch.getRawData("donors", params).subscribe(
      (data: any[]) => {
        console.log(JSON.stringify(data));
        this.rawData = data;
      });
  }

  isSingleDonorSelected(): boolean {
    return this.selectedRows.length === 1;
  }

  onSelectDonor(): void {
    if (this.selectedRows.length === 1) {
      this.servicesearch.emitDonorSelected(this.selectedRows[0]);
    } else if (this.selectedRows.length > 1) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select only one donor.' });
    }
  }

  onAddDonor(): void {
    this.servicesearch.emitAddNewDonorSelected();
  }

  onCancel(): void {
    this.selectedRows = [];
    this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: 'Selection cancelled.' });
  }
}
