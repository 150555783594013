import { Component, OnInit, ViewChild } from '@angular/core';
import { Stepper, StepperModule } from 'primeng/stepper';
import { ButtonModule } from 'primeng/button';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'primeng/accordion';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CampaignService } from '../../services/campaign.service';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Campaign, CampaignApiResponse, ChildCampaign, ChildCampaignApiResponse, UserInfo } from '../../models/UserInviteModel';
import { AsyncValidatorService } from '../../validators/async.validator.service';

@Component({
  selector: 'app-user-campaign-invite',
  templateUrl: './user-campaign-invite.component.html',
  styleUrls: ['./user-campaign-invite.component.scss'],
  standalone: true,
  imports: [
            CommonModule,
            FormsModule,
            ReactiveFormsModule,
            AccordionModule,
            InputTextModule,
            MultiSelectModule,
            StepperModule,
            ButtonModule,
            InputSwitchModule,
            ToastModule,
            ConfirmDialogModule,
            CheckboxModule,
            RadioButtonModule,
            DropdownModule,
            ProgressSpinnerModule],
  providers: [
    ConfirmationService,
    MessageService,
    CampaignService
  ]
})
export class UserCampaignInviteComponent implements OnInit {
  // Step 1
  personalInfoForm: FormGroup;

  // Step 2
  searchForm: FormGroup = this.fb.group({
    search: [''],
  });
  campaigns: Array<Campaign> = [];
  showCampaignSearchLoader = false;
  filteredCampaigns = [...this.campaigns];

  // Step 3
  userInviteForm: FormGroup;
  userPanelNextCallback: any;
  step3DataIsInitialized: boolean = false;
  allFeatures: { [key: string]: string } = {
    '1': 'Campaign Dashboard',
    '2': 'Donor & Donations',
    '3': 'Donor Dedupe',
    '4': 'Donor Flags',
    '5': 'Donor Call Sheets' ,
    '6':'Add Single Donation',
    '7': 'Donation Batches' ,
    '8': 'Donation Flags',
    '9': 'Events',
    '10': 'Fundraisers',
    '11': 'Initiatives',
    '12': 'Expenses',
    '13': 'Add New Expense',
    '14': 'Reporting Center',
    '15': 'FEC',
    '16': 'JFC',
    '17': 'My Fundraiser Tally',
    '18': 'Club Settings'
  };

  // All Access Vortex3
  isVortex3Collapsed = true;
  vortex3FirstTableFeatures = [
    { id: 1, name: 'Campaign Dashboard' },
    { id: 2, name: 'Donor & Donations' },
    { id: 3, name: 'Donor Dedupe' },
    { id: 4, name: 'Donor Flags' },
    { id: 5, name: 'Donor Call Sheets' },
    { id: 6, name: 'Add Single Donation' },
    { id: 7, name: 'Donation Batches' },
    { id: 8, name: 'Donation Flags' }
  ];
  vortex3SecondTableFeatures = [
    { id: 9, name: 'Events' },
    { id: 10, name: 'Fundraisers' },
    { id: 11, name: 'Initiatives' },
    { id: 12, name: 'Expenses' },
    { id: 13, name: 'Add New Expense' },
    { id: 14, name: 'Reporting Center' },
    { id: 15, name: 'FEC' },
    { id: 16, name: 'JFC' }
  ];

  // Selected options for both tables
  selectedVortex3Option1: { [key: number]: string } = { 1: 'NoAccess', 2: 'FullAccess', 3: 'ReadOnly' };
  selectedVortex3Option2: { [key: number]: string } = {};
  

  // All Access Vortex Lite
  isAllVortexLiteCollapsed = true;
  vortexLiteFirstTableFeatures = [
    { id: 17, name: 'My Fundraiser Tally' },
    { id: 9, name: 'Events' },
    { id: 10, name: 'Fundraisers' },
    { id: 4, name: 'Donor Flags' },
    { id: 18, name: 'Club Settings' }
  ];
  selectedVortexLiteOption1: { [key: number]: string } = { 17: 'NoAccess', 18: 'FullAccess', 19: 'ReadOnly' };


  // All Access Apex
  isAllApexCollapsed = true;
  apexFirstTableFeatures = [
    { id: 19, name: 'My Widgets' },
    { id: 20, name: 'APEX Settings' }
  ];
  selectedApexOption1: { [key: number]: string } = { 22: 'NoAccess', 23: 'FullAccess' };
  vortex3: string = 'vortex3';
  vortexLite: string = 'vortexLite';
  apex: string = 'apex';

  // Accordion Properties
  selectedCampaigns: Campaign[] = [];
  glFormGroup: FormGroup;

  @ViewChild('userInviteStepper') userInviteStepper!: Stepper;
  isVortex3ExpandedArray: Array<boolean> = [];
  isVortexLiteExpandedArray: Array<boolean> = [];
  isApexExpandedArray: Array<boolean> = [];
  roles = [
    { label: 'Admin', value: 1 },
    { label: 'User', value: 2 },
    { label: 'Guest', value: 3 }
  ];
  usersList: UserInfo[] = [];

  constructor(private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private asyncValidatorService: AsyncValidatorService,
    private campaignService: CampaignService) {
    // Initialize forms for each step
    this.personalInfoForm = this.fb.group({
      firstName: ['',
                  [Validators.required,
                    Validators.pattern(/^[a-zA-Z ]*$/)]],
      lastName: ['', [Validators.required,
                      Validators.pattern(/^[a-zA-Z ]*$/)]],
      email: ['', [Validators.required,
                    Validators.email],
                    [this.asyncValidatorService.validateUniqueEmail()]],
      isAdmin: [false]
    });

    this.glFormGroup = this.fb.group({
      firstName: [''],
      lastName: [''],
      email: [''],
      role: [null]
    });

    this.userInviteForm = this.fb.group({
      userInviteCampaigns: this.fb.array([])
    });
  }

  filterCampaigns() {
    const searchValue = this.searchForm.get('search')?.value?.toLowerCase();
    this.filteredCampaigns = this.campaigns.filter((campaign: Campaign) =>
      campaign.name.toLowerCase().includes(searchValue)
    );
  }

  ngOnInit() {
    this.personalInfoForm.controls['email'].valueChanges.subscribe({
      next: (data) => {
        console.debug(this.personalInfoForm.get('email')?.errors);
      }
    });
  }

  

  loadCampaignSearchForm() {
    const campagnFormControls = this.campaigns.reduce((res: any, campaign: Campaign) => {
      if (campaign.childCampaigns &&
            campaign.childCampaigns.length === 0) {
        res[campaign.id] = new FormControl(false);
        return res;
      } else {
        return res;
      }
    }, {});

    const childCampaignFormControls = this.campaigns.reduce((res: any, campaign: Campaign) => {
      if (campaign.childCampaigns &&
        campaign.childCampaigns.length > 0) {
        campaign.childCampaigns.forEach((cCmp: ChildCampaign) => {
          res[`${campaign.id}-${cCmp.id}`] = new FormControl(false);
        });

        return res;
      } else {
        return res;
      }
    }, {});
    this.searchForm = this.fb.group({
      search: [''],
      ...campagnFormControls,
      ...childCampaignFormControls
    });
  }

  loadCampaigns() {
    this.showCampaignSearchLoader = true;
    this.campaignService.getAllCampaignsWithChildCampaigns().subscribe({
      next: (data) => {
        this.campaigns = data.map((d: CampaignApiResponse) => {
          return {
            id: d.campaignId,
            name: d.name,
            isEntityCampaign: d.childCampaigns.length > 0 ? true: false,
            childCampaigns: d.childCampaigns.map((c: ChildCampaignApiResponse) => {
                                  return { id: c.childCampaignId, name: c.name }
                              }) };
        });
        this.campaigns.sort((a, b) => a.name.localeCompare(b.name));
        this.filteredCampaigns = [...this.campaigns];
        this.loadCampaignSearchForm();
        this.showCampaignSearchLoader = false;
      },
      error: (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while loading campaign list!' });
        this.showCampaignSearchLoader = false;
      }
    });
  }

  toggleVortex3ExpandIndex(index: number) {
    this.isVortex3ExpandedArray[index] = !this.isVortex3ExpandedArray[index];
  }

  toggleVortexLiteExpandIndex(index: number) {
    this.isVortexLiteExpandedArray[index] = !this.isVortexLiteExpandedArray[index];
  }

  toggleApexExpandIndex(index: number) {
    this.isApexExpandedArray[index] = !this.isApexExpandedArray[index];
  }

  toggleVortex3Collapse() {
    this.isVortex3Collapsed = !this.isVortex3Collapsed;
    console.debug(this.userInviteForm);
  }

  toggleVortexLiteCollapse() {
    this.isAllVortexLiteCollapsed = !this.isAllVortexLiteCollapsed;
  }

  toggleApexCollapse() {
    this.isAllApexCollapsed = !this.isAllApexCollapsed;
  }

  get email() {
    return this.personalInfoForm.controls['email'];
  }

  get firstName() {
    return this.personalInfoForm.controls['firstName'];
  }

  get lastName() {
    return this.personalInfoForm.controls['lastName'];
  }

  get adminUser() {
    return this.personalInfoForm.controls['isAdmin'].value;
  }


  onAdminClick = (event: any) => {
    console.debug('Admin switch clicked!');
  }

  onStep1UserPanelNextClick(event: any) {
    // Need to submit the user form to backen and show message on the UI.
    if (this.personalInfoForm.valid) {
      console.debug(this.personalInfoForm.value);
      if (this.personalInfoForm.controls["isAdmin"].value === true) {
        this.confirmationService.confirm({
          target: event.target as EventTarget,
          message: 'Are you sure you want to assign the admin role to this user?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          acceptIcon: "none",
          rejectIcon: "none",
          rejectButtonStyleClass: "p-button-text",
          accept: () => {
            this.sendAdminUserInvite();
            //this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Admin invite has been sent successfully!' });
          },
          reject: () => {
            //this.personalInfoForm.patchValue({ "isAdmin": false });
            // this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
          }
        });
      }
      else {
        this.userInviteStepper.activeStep = 1;
        this.loadCampaigns();
      }
    }
  }

  sendAdminUserInvite() {
    this.campaignService.sendAdminUserInvite(this.personalInfoForm.value).subscribe({
      next: (data) => {
        this.messageService.add({ severity: 'info', summary: 'Email Sent', detail: 'User registration email invite sent successfully!' });
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Invite Error', detail: 'An error occurred while sending the user invite. Please try again later.' });
      }
    });
  }

  stepChange = (step: number) => {
    console.debug(`Step Number: ${step}`);
    if (step === 2) {
      console.debug('Personal Info:', this.personalInfoForm.value);
      console.debug('Selected Campaigns:', this.selectedCampaigns);
      if (this.selectedCampaigns.length === 0) {
        this.messageService.add({ severity: 'info', summary: 'Select Campaign', detail: 'Please select at least one campaign to continue!' });
        this.userInviteStepper.activeStep = 1;
      }
      this.initializeStep3();
    } 
  }

  initializeVortex3Table1Features() {
    return {
      1: 'NoAccess',
      2: 'NoAccess',
      3: 'NoAccess',
      4: 'NoAccess',
      5: 'NoAccess',
      6: 'NoAccess',
      7: 'NoAccess',
      8: 'NoAccess'
    };
  }

  initializeVortex3Table2Features() {
    return {
      9: 'NoAccess',
      10: 'NoAccess',
      11: 'NoAccess',
      12: 'NoAccess',
      13: 'NoAccess',
      14: 'NoAccess',
      15: 'NoAccess',
      16: 'NoAccess'
    };
  }

  initiallizeVortexLiteFeatures() {
    return {
      17: 'NoAccess',
      9: 'NoAccess',
      10: 'NoAccess',
      4: 'NoAccess',
      18: 'NoAccess'
    };
  }

  initiallizeApexFeatures() {
    return {
      19: 'NoAccess',
      20: 'NoAccess'
    }
  }

  // Function to reduce object into form controls
  reduceObjectToFormControls(obj: { [key: number]: string }): { [key: string]: FormControl } {
    const controls: { [key: string]: FormControl } = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Create a form control where the key is the control name and the value is the form control's initial value
        controls[key] = new FormControl({ value: obj[key], disabled: false});
      }
    }

    return controls;
  }

  showChildCampaigns(campaignIndex: number): boolean {
    return this.getChildCampaigns(campaignIndex).length > 0;
  }

  appendAllProductNameToKey(tableFeatures: { [key: string]: any }, productName: string): {[key:string]: any} {
    const appendedTable1Features = Object.keys(tableFeatures).reduce((acc, key) => {
      acc[`All-${productName}-${key}`] = tableFeatures[key];
      return acc;
    }, {} as { [key: string]: any });

    return appendedTable1Features;
  }

  initializeStep3() {
    this.campaignService.getAllUserList().subscribe({
      next: (data) => {
        this.usersList = data;
      }
    });
    this.isVortex3ExpandedArray = [];
    this.isVortexLiteExpandedArray = [];
    this.isApexExpandedArray = [];
    this.selectedCampaigns.forEach(cmp => {
      this.isVortex3ExpandedArray.push(false);
      this.isVortexLiteExpandedArray.push(false);
      this.isApexExpandedArray.push(false);
    })
    const vortex3FirstTableFeatureControls = this.reduceObjectToFormControls(this.initializeVortex3Table1Features());
    const vortex3SecondTableFeatureControls = this.reduceObjectToFormControls(this.initializeVortex3Table2Features());
    const vortexLiteFirstTableFeatureControls = this.reduceObjectToFormControls(this.initiallizeVortexLiteFeatures());
    const apexFirstTableFeatureControls = this.reduceObjectToFormControls(this.initiallizeApexFeatures());

    // Create dynamic key to radio buttons
    const appendedAllV3Table1Features = this.appendAllProductNameToKey(vortex3FirstTableFeatureControls, this.vortex3);
    const appendedAllV3Table2Features = this.appendAllProductNameToKey(vortex3SecondTableFeatureControls, this.vortex3);
    const appendedVortexLiteTable1Features = this.appendAllProductNameToKey(vortexLiteFirstTableFeatureControls, this.vortexLite);
    const appendedApexTable1Features = this.appendAllProductNameToKey(apexFirstTableFeatureControls, this.apex);

    this.userInviteForm = this.fb.group({
      allAccess: this.fb.group({
          vortex3: this.fb.group({
            disabled: new FormControl(false),
            ...appendedAllV3Table1Features,
            ...appendedAllV3Table2Features,
            gl: this.fb.group({
              division: new FormControl(null, [Validators.min(1),
                                              Validators.max(9),
                                              Validators.pattern('^[0-9]*$')]),
              department: new FormControl(null, [Validators.min(10),
                                                Validators.max(99),
                                                Validators.pattern('^[0-9]*$')]),
              subDepartment: new FormControl(null, [Validators.min(100),
                                                    Validators.max(999),
                                                    Validators.pattern('^[0-9]*$')]),
              reportsTo: new FormControl(null)
            })
          }),
          vortexLite: this.fb.group({
            disabled: new FormControl(true),
            ...appendedVortexLiteTable1Features
          }),
          apex: this.fb.group({
            disabled: new FormControl(true),
            ...appendedApexTable1Features
          })
        }),
      userInviteCampaigns: this.fb.array(this.initializeCampaignForms())
    });

    this.step3DataIsInitialized = true;
  }

  showRadioButton(feature: any): boolean {

    return ![3, 5, 6, 13, 14, 15, 16].some((featureId: number) => featureId === feature.id);
  }

  initializeChildCampaignsArray(cCmp: Array<ChildCampaign>): FormGroup[] {
    return cCmp.map((cCmp: ChildCampaign) => {
      return this.fb.group({
        id: new FormControl(cCmp.id),
        name: new FormControl(cCmp.name),
        selected: new FormControl(true)
      })
    });
  }

  appendCampaignIdToKey(campaignId: number, tableFeatures: { [key: string]: any }, productName: string): { [key: string]: any } {
    const appendedTable1Features = Object.keys(tableFeatures).reduce((acc, key) => {
      acc[`${campaignId}-${productName}-${key}`] = tableFeatures[key];
      return acc;
    }, {} as { [key: string]: any });

    return appendedTable1Features;
  }

  initializeCampaignForms(): FormGroup[] {
    return this.selectedCampaigns.map((cmp: Campaign) => {

      const vortex3FirstTableFeatureControls = this.reduceObjectToFormControls(this.initializeVortex3Table1Features());
      const vortex3SecondTableFeatureControls = this.reduceObjectToFormControls(this.initializeVortex3Table2Features());
      const vortexLiteFirstTableFeatureControls = this.reduceObjectToFormControls(this.initiallizeVortexLiteFeatures());
      const apexFirstTableFeatureControls = this.reduceObjectToFormControls(this.initiallizeApexFeatures());

      // Create dynamic key to radio buttons
      const appendedV3Table1Features = this.appendCampaignIdToKey(cmp.id, vortex3FirstTableFeatureControls, this.vortex3);
      const appendedV3Table2Features = this.appendCampaignIdToKey(cmp.id, vortex3SecondTableFeatureControls, this.vortex3);
      const appendedVortexLiteFeatures = this.appendCampaignIdToKey(cmp.id, vortexLiteFirstTableFeatureControls, this.vortexLite);
      const appendedApexFeatures = this.appendCampaignIdToKey(cmp.id, apexFirstTableFeatureControls, this.apex);

      return this.fb.group({
        id: new FormControl(cmp.id),
        name: new FormControl(cmp.name),
        childCampaigns: cmp?.childCampaigns ? this.fb.array(this.initializeChildCampaignsArray(cmp.childCampaigns)) : [],
        campaignVortex3: this.fb.group({
          disabled: new FormControl(false),
          ...appendedV3Table1Features,
          ...appendedV3Table2Features,
          gl: this.fb.group({
            division: new FormControl(null, [Validators.min(1),
                          Validators.max(9),
                          Validators.pattern('^[0-9]*$')]),
            department: new FormControl(null, [Validators.min(10),
                          Validators.max(99),
                          Validators.pattern('^[0-9]*$')]),
            subDepartment: new FormControl(null, [Validators.min(100),
                          Validators.max(999),
                          Validators.pattern('^[0-9]*$')]),
            reportsTo: new FormControl(null)
          })
        }),
        campaignVortexLite: this.fb.group({
          disabled: new FormControl(false),
          ...appendedVortexLiteFeatures
        }),
        campaignApex: this.fb.group({
          disabled: new FormControl(false),
          ...appendedApexFeatures
        }),
       
      })
    });
  }

  getCampaignVortex3GlControl(campaignIndex: any, controlName: string): FormControl {
   // const campaignId = campaign.get('id')?.value;
    return this.step3Campaigns.at(campaignIndex).get('campaignVortex3.gl')?.get(controlName) as FormControl;
  }

  get getAllAccessVortex3GlDivision(): FormControl {
    return this.userInviteForm.get('allAccess.vortex3.gl.division') as FormControl;
  }

  get getAllAccessVortex3GlDepartment(): FormControl {
    return this.userInviteForm.get('allAccess.vortex3.gl.department') as FormControl;
  }

  get getAllAccessVortex3GlSubDepartment(): FormControl {
    return this.userInviteForm.get('allAccess.vortex3.gl.subDepartment') as FormControl;
  }

  getAllAccessProductDisabled(productName: string): boolean {
    return this.userInviteForm.get('allAccess')?.get(productName)?.get('disabled')?.value;
  }

  onAllAccessProductClicked(productName: string) {
    const productDisabled: boolean = this.userInviteForm.get('allAccess')?.get(productName)?.get('disabled')?.value;
    this.userInviteForm.get('allAccess')?.get(productName)?.get('disabled')?.setValue(!productDisabled);
  }

  getCampaignProductDisabled(campaignIndex: number, productName: string): string {
    return  this.step3Campaigns.at(campaignIndex).get(productName)?.get('disabled')?.value;
  }

  onCampaignProductClicked(campaignIndex: number, productName: string) {
    const productDisabled: boolean = this.step3Campaigns.at(campaignIndex).get(productName)?.get('disabled')?.value;
    this.step3Campaigns.at(campaignIndex).get(productName)?.get('disabled')?.setValue(!productDisabled);
  }

  removeAllToKey(tableFeatures: { [key: string]: any }): { [key: string]: any } {
    const appendedTable1Features = Object.keys(tableFeatures).reduce((acc, key) => {

      if (key.toLowerCase().includes('disabled')) {
        return acc;
      }

      if (key.toLowerCase().includes('gl')) {
        return acc;
      }
      const replacedAllKey = key.split('-')[2];
      if (replacedAllKey) {
        acc[replacedAllKey] = tableFeatures[key];
      }
      
      return acc;
    }, {} as { [key: string]: any });

    return appendedTable1Features;
  }

  removeCampaignIdToKey(tableFeatures: { [key: string]: any }): { [key: string]: any } {
    const appendedTable1Features = Object.keys(tableFeatures).reduce((acc, key) => {
      if (key.toLowerCase().includes('disabled')) {
        return acc;
      }

      if (key.toLowerCase().includes('gl')) {
        return acc;
      }
      const replaceCampaignId = key.split('-')[2];
      if (replaceCampaignId) {
        acc[replaceCampaignId] = tableFeatures[key];
      } 
     
      return acc;
    }, {} as { [key: string]: any });

    return appendedTable1Features;
  }

  assignToAllCampaigns() {
    const usrInvForm = this.userInviteForm.value;
    const vortex3FormValue = this.removeAllToKey(usrInvForm.allAccess.vortex3);
    let vortex3Table1Features: any = {};
    let vortex3Table2Features: any = {};
    let index = 0;
    Object.keys(vortex3FormValue).forEach(key => {
      if (index < 8) {
        vortex3Table1Features[key] = vortex3FormValue[key];
      } else {
        vortex3Table2Features[key] = vortex3FormValue[key];
      }
      index++;
    });
    const vortex3Gl = usrInvForm.allAccess.vortex3.gl;
    const vortexLiteTableFeatures = this.removeAllToKey(usrInvForm.allAccess.vortexLite);
    const apexTableFeatures = this.removeAllToKey(usrInvForm.allAccess.apex);
    const vortex3Disabled = usrInvForm.allAccess.vortex3.disabled;
    const apexDisabled = usrInvForm.allAccess.apex.disabled;
    const vortexLiteDisabled = usrInvForm.allAccess.vortexLite.disabled;
    (this.userInviteForm.controls['userInviteCampaigns'] as FormArray).controls.forEach((cmpForm: any) => {
      const appendedV3Table1Features = this.appendCampaignIdToKey(cmpForm.get('id').value, vortex3Table1Features, this.vortex3);
      const appendedV3Table2Features = this.appendCampaignIdToKey(cmpForm.get('id').value, vortex3Table2Features, this.vortex3);
      const appendedVortexLiteFeatures = this.appendCampaignIdToKey(cmpForm.get('id').value, vortexLiteTableFeatures, this.vortexLite);
      const appendedApexFeatures = this.appendCampaignIdToKey(cmpForm.get('id').value, apexTableFeatures, this.apex);
      cmpForm.get('campaignVortex3').setValue({ ...appendedV3Table1Features, ...appendedV3Table2Features, disabled: vortex3Disabled, gl: vortex3Gl });
      cmpForm.get('campaignVortexLite').setValue({ ...appendedVortexLiteFeatures, disabled: vortexLiteDisabled });
      cmpForm.get('campaignApex').setValue({ ...appendedApexFeatures, disabled: apexDisabled });
    });
  }

  applyAll(event: any) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure you want to apply these settings to all campaigns?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.assignToAllCampaigns();
      },
      reject: () => {
      }
    });
  }

  sendNonAdminUserInvite() {
    const usrInvForm = this.userInviteForm.value;
    usrInvForm.firstName = this.personalInfoForm.value?.firstName;
    usrInvForm.lastName = this.personalInfoForm.value?.lastName;
    usrInvForm.email = this.personalInfoForm.value?.email;
    usrInvForm.allAccess.vortex3.permissions = this.removeAllToKey(usrInvForm.allAccess.vortex3);
    usrInvForm.allAccess.vortexLite.permissions = this.removeAllToKey(usrInvForm.allAccess.vortexLite);
    usrInvForm.allAccess.apex.permissions = this.removeAllToKey(usrInvForm.allAccess.apex);
    usrInvForm.userInviteCampaigns.forEach((cmp: any) => {
      cmp.campaignVortex3.permissions = this.removeCampaignIdToKey(cmp.campaignVortex3);
      cmp.campaignVortexLite.permissions = this.removeCampaignIdToKey(cmp.campaignVortexLite);
      cmp.campaignApex.permissions = this.removeCampaignIdToKey(cmp.campaignApex);
    });
    console.debug(usrInvForm);
    this.campaignService.sendNonAdminUserInvite(usrInvForm).subscribe({
      next: (data) => {
        this.messageService.add({ severity: 'info', summary: 'Email Sent', detail: 'User registration email invite sent successfully!' });
        this.personalInfoForm.reset();
        this.selectedCampaigns = [];
        this.userInviteStepper.activeStep = 0;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Invite Error', detail: 'An error occurred while sending the user invite. Please try again later.' });
      }
    });
  }

  isAllProductsAndFeaturesValid(): boolean {
    const usrInvFrm = this.userInviteForm.value;

    // Helper function to check if a feature group is valid
    const isFeatureGroupValid = (featureGroup: any): boolean => {
      if (featureGroup.disabled) return true;

      const featureFormValue = this.removeAllToKey(featureGroup);
      return Object.keys(featureFormValue).some((key: string) =>
        featureFormValue[key] && featureFormValue[key] !== 'NoAccess'
      );
    };

    // Check each feature group
    const { vortex3, vortexLite, apex } = usrInvFrm.allAccess;
    return isFeatureGroupValid(vortex3) && isFeatureGroupValid(vortexLite) && isFeatureGroupValid(apex);
  }

  isCampaignProductsAndFeaturesValid(campaignIndex: string): boolean {
    const usrCmpInvFrm = this.userInviteForm.value?.userInviteCampaigns[campaignIndex];

    // Helper function to check if a feature group is valid
    const isFeatureGroupValid = (featureGroup: any): boolean => {
      if (featureGroup.disabled) return true;

      const featureFormValue = this.removeCampaignIdToKey(featureGroup);
      return Object.keys(featureFormValue).some((key: string) =>
        featureFormValue[key] && featureFormValue[key] !== 'NoAccess'
      );
    };

    // Check each feature group
    const { campaignVortex3, campaignVortexLite, campaignApex } = usrCmpInvFrm;
    return isFeatureGroupValid(campaignVortex3) && isFeatureGroupValid(campaignVortexLite) && isFeatureGroupValid(campaignApex);
  }

  isCustomValidationsPassed(): boolean {
    if (!this.isAllProductsAndFeaturesValid()) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please select at least one feature for the selected Campaign.',
        life: 10000
      });
      return false;
    }

    let cmpIndex: any = 0;
    let isAllCampaignFeaturesValid = true;
    let isAllCampaignProductsSelected = true;
    for (const cmp of this.userInviteForm.value?.userInviteCampaigns) {

      if (cmp?.campaignVortex3?.disabled &&
        cmp?.campaignVortexLite?.disabled &&
        cmp?.campaignApex?.disabled) {

        isAllCampaignProductsSelected = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Please select at least one product in the campaign: ${cmp.name}!`,
          life: 10000
        });
        break; // Breaks out of the loop
      }

      if (!this.isCampaignProductsAndFeaturesValid(cmpIndex)) {
        isAllCampaignFeaturesValid = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Please select at least one feature for the selected Campaign: ${cmp.name}!`,
          life: 10000
        });
        break; // Breaks out of the loop
      }
      cmpIndex = cmpIndex + 1;
    }

    if (!isAllCampaignFeaturesValid || !isAllCampaignProductsSelected) {
      return false;
    }

    return true;
  }

  inviteUser(event: any) {

    if (!this.isCustomValidationsPassed()) {
      return;
    }

    if (this.userInviteForm.valid) {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Are you sure you want to send user invite?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: "none",
        rejectIcon: "none",
        rejectButtonStyleClass: "p-button-text",
        accept: () => {
          this.sendNonAdminUserInvite();
        },
        reject: () => {
        }
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fix the validation errors and try again.' });
    }
  }

  get step3Campaigns(): FormArray {
    return this.userInviteForm.get('userInviteCampaigns') as FormArray;
  }

  getFeatureName(campaign: any, feature: any, productName: string): string {
    const campaignId = campaign.get('id')?.value;
    const featureId = feature.id;
    return `${campaignId}-${productName}-${featureId}`;
  }

  getAllFeatureName(feature: any, productName: string): string {
    const featureId = feature.id;
    return `All-${productName}-${featureId}`;
  }

  getChildCampaigns(campaignIndex: number): FormArray {
    return this.step3Campaigns.at(campaignIndex).get('childCampaigns') as FormArray;
  }

  getChildCampaignName(campaignIndex: number, childCampaignIndex: number): string  {
    return this.getChildCampaigns(campaignIndex).at(childCampaignIndex).get('name')?.value;
  }

  getChildCampaignSelected(campaignIndex: number, childCampaignIndex: number): string {
    return this.getChildCampaigns(campaignIndex).at(childCampaignIndex).get('selected')?.value;
  }

  // Called when selecting campaigns in step 2
  onCampaignSelectionChange(event: any) {
    this.selectedCampaigns = event.value;
  }

  onCampaignSelected(event: any, selectedFormControl: AbstractControl, selectedControlId: number, selectedFormControlName: string, isEntityCampaign: boolean) {
   
    if (selectedFormControl.value) {
      if (this.selectedCampaigns.filter(c => c.id === selectedControlId).length === 0) {
        this.selectedCampaigns.push({
          id: selectedControlId,
          name: selectedFormControlName,
          isEntityCampaign: isEntityCampaign
        });
      }
    } else {
      const index = this.selectedCampaigns.findIndex(d => d.id == selectedControlId);
      if (index !== -1) {
        this.selectedCampaigns.splice(index, 1);
      }
    }
  }

  onChildCampaignSelected(event: any, selectedFormControl: AbstractControl, campaignId: number, campaignName: string, isEntityCampaign: boolean, childCampaignId: number, childCampaignName: string) {

    if (selectedFormControl.value) {
      if (this.selectedCampaigns.filter(c => c.id === campaignId).length === 0) {
        this.selectedCampaigns.push({
          id: campaignId,
          name: campaignName,
          isEntityCampaign: isEntityCampaign,
          childCampaigns: [
            {
              id: childCampaignId,
              name: childCampaignName
            }
          ]
        });
      } else {
        let parentCampaign = this.selectedCampaigns.filter(c => c.id === campaignId)[0];
        parentCampaign.childCampaigns?.push({
          id: childCampaignId,
          name: childCampaignName
        });
      }
    } else {
      let parentCampaign = this.selectedCampaigns.filter(c => c.id === campaignId)[0];
      if (parentCampaign) {
        if (parentCampaign.childCampaigns?.filter(c => c.id === childCampaignId)[0]) {
          const childCampaignIndex = parentCampaign.childCampaigns.findIndex(c => c.id == childCampaignId);
          if (childCampaignIndex !== -1) {
            parentCampaign.childCampaigns.splice(childCampaignIndex, 1);
          }

          if (parentCampaign.childCampaigns.length === 0) {
            const campaignIndex = this.selectedCampaigns.findIndex(d => d.id == campaignId);
            if (campaignIndex !== -1) {
              this.selectedCampaigns.splice(campaignIndex, 1);
            }
          }
        }
      }
    }
  }

  getNestedFormGroup(name: string): FormGroup {
    return this.userInviteForm.get(name) as FormGroup;
  }

  getNestedFormControlValue(groupName: string, controlName: string): any {
    return this.getNestedFormGroup(groupName).get(controlName)?.value;
  }

  setNestedFormControlValue(groupName: string, controlName: string, value: any) {
    const control = this.getNestedFormGroup(groupName).get(controlName);
    control?.setValue(value);
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  setRadioButtonValue(rbControl: FormControl, value: string) {
    rbControl.setValue(value);
  }

  disableOrEnableRadiobuttonControl(rbContol: FormControl, disable: boolean) {
    if (disable === true) {
      this.setRadioButtonValue(rbContol, '');
      rbContol.disable();
    } else {
      rbContol.enable();
    }
  }

  getFeatureNameById(featureId: string): string {
    return this.allFeatures[featureId];
  }

  setRadioButtonsValue(index: number, campaignId: number, product: string, features: string, restrictedAccess: string | undefined) {
    let invalidFeatureValues = '';
    features.split(',').map((featureId: string) => {
      const controlName = `${campaignId}-${product}-${featureId}`;
      const radioButtonControl = this.step3Campaigns.at(index).get('campaignVortex3')?.get(controlName);
      if (restrictedAccess && radioButtonControl?.value === restrictedAccess) {
        invalidFeatureValues = invalidFeatureValues+ ' ' + this.getFeatureNameById(featureId);
        this.setRadioButtonValue(radioButtonControl as FormControl, 'NoAccess');
      }
      if (!restrictedAccess) {
        //if (radioButtonControl?.value !== 'NoAccess') {
        //  invalidFeatureValues = invalidFeatureValues + ' ' + this.getFeatureNameById(featureId);
        //}
        this.setRadioButtonValue(radioButtonControl as FormControl, 'NoAccess');
      }
    });

    if (invalidFeatureValues !== '') {
      this.messageService.add({
        severity: 'info',
        summary: 'Information',
        detail: `The following features: ${invalidFeatureValues} shouldn't have ${restrictedAccess}.`,
        life: 10000
      });
    }
  }

  onCampaignFeatureClick(formgroupName: string, index: number, campaign: any, product:any, feature: any, value: any) {

    const campaignId = campaign.get('id').value;
    if (value !== 'NoAccess') {
      //Check if product has been selected
      const isVortex3Disabled = this.getCampaignProductDisabled(index, formgroupName);
      if (isVortex3Disabled) {
        this.messageService.add({
          severity: 'info',
          summary: 'Information',
          detail: `Please enable the product before selecting a feature.`,
          life: 10000
        });
        const controlName = `${campaignId}-${product}-${feature.id}`;
        const radioButtonControl = this.step3Campaigns.at(index).get(formgroupName)?.get(controlName);
        this.setRadioButtonValue(radioButtonControl as FormControl, 'NoAccess');
      }
      return;
    }

    
    if (feature.id === 2 && value === 'ReadOnly') {
      // User selected read-only for donor&donation feature, need to check
      // and set feature 5,6 as NoAccess
      this.setRadioButtonsValue(index, campaignId, 'vortex3', '5,6', undefined);
      // Check if feature 7,8 has FullAccess Flip to NoAccess and give a message
      this.setRadioButtonsValue(index, campaignId, 'vortex3', '7,8', 'FullAccess');
    }

    if (feature.id === 12 && value === 'ReadOnly') {
      // User selected read-only for expense feature, need to check
      // Check if feature 13 has FullAccess Flip to NoAccess and give a message
      this.setRadioButtonsValue(index, campaignId, 'vortex3', '13', 'FullAccess');
    }
  }

  setAllCmpRadioButtonsValue(product: string, features: string, restrictedAccess: string | undefined) {
    let invalidFeatureValues = '';
    features.split(',').map((featureId: string) => {
      const controlName = `All-${product}-${featureId}`;
      const radioButtonControl = this.userInviteForm.get('allAccess.vortex3')?.get(controlName);
      if (restrictedAccess && radioButtonControl?.value === restrictedAccess) {
        invalidFeatureValues = invalidFeatureValues + ' ' + this.getFeatureNameById(featureId);
        this.setRadioButtonValue(radioButtonControl as FormControl, 'NoAccess');
      }
      if (!restrictedAccess) {
        //if (radioButtonControl?.value !== 'NoAccess') {
        //  invalidFeatureValues = invalidFeatureValues + ' ' + this.getFeatureNameById(featureId);
        //}
        this.setRadioButtonValue(radioButtonControl as FormControl, 'NoAccess');
      }
    });

    if (invalidFeatureValues !== '') {
      this.messageService.add({
        severity: 'info',
        summary: 'Information',
        detail: `The following features: ${invalidFeatureValues} shouldn't have ${restrictedAccess}.`,
        life: 10000
      });
    }
  }

  onAllVortexLiteFeatureClick(feature: any, value: any) {
    if (value !== 'NoAccess') {
      //Check if product has been selected
      const isVortex3Disabled = this.getAllAccessProductDisabled('vortexLite');
      if (isVortex3Disabled) {
        this.messageService.add({
          severity: 'info',
          summary: 'Information',
          detail: `Please enable the product before selecting a feature.`,
          life: 10000
        });
        const controlName = `All-vortexLite-${feature.id}`;
        const radioButtonControl = this.userInviteForm.get('allAccess.vortexLite')?.get(controlName);
        this.setRadioButtonValue(radioButtonControl as FormControl, 'NoAccess');
      }
      return;
    }
  }

  onAllApexFeatureClick(feature: any, value: any) {
    if (value !== 'NoAccess') {
      //Check if product has been selected
      const isVortex3Disabled = this.getAllAccessProductDisabled('apex');
      if (isVortex3Disabled) {
        this.messageService.add({
          severity: 'info',
          summary: 'Information',
          detail: `Please enable the product before selecting a feature.`,
          life: 10000
        });
        const controlName = `All-apex-${feature.id}`;
        const radioButtonControl = this.userInviteForm.get('allAccess.apex')?.get(controlName);
        this.setRadioButtonValue(radioButtonControl as FormControl, 'NoAccess');
      }
      return;
    }
  }

  onAllCampaignFeatureClick(feature: any, value: any) {

    if (value !== 'NoAccess') {
      //Check if product has been selected
      const isVortex3Disabled = this.getAllAccessProductDisabled('vortex3');
      if (isVortex3Disabled) {
        this.messageService.add({
          severity: 'info',
          summary: 'Information',
          detail: `Please enable the product before selecting a feature.`,
          life: 10000
        });
        const controlName = `All-vortex3-${feature.id}`;
        const radioButtonControl = this.userInviteForm.get('allAccess.vortex3')?.get(controlName);
        this.setRadioButtonValue(radioButtonControl as FormControl, 'NoAccess');
      }
      return;
    }

    if (feature.id === 2 && value === 'ReadOnly') {
      // User selected read-only for donor&donation feature, need to check
      // and set feature 5,6 as NoAccess
      this.setAllCmpRadioButtonsValue('vortex3', '5,6', undefined);
      // Check if feature 7,8 has FullAccess Flip to NoAccess and give a message
      this.setAllCmpRadioButtonsValue('vortex3', '7,8', 'FullAccess');
    }

    if (feature.id === 12 && value === 'ReadOnly') {
      // User selected read-only for expense feature, need to check
      // Check if feature 13 has FullAccess Flip to NoAccess and give a message
      this.setAllCmpRadioButtonsValue('vortex3', '13', 'FullAccess');
    }
  }

  updateAllFeatureValue(feature: any, value: string) {
    this.setNestedFormControlValue('allAccess.vortex3', `All-${feature.id}`, value);
  }

  onStep3ChildCampaignSelected(event: any, selectedFormControl: AbstractControl, selectedFormControlName: string) {
    console.debug(event);
    console.debug(selectedFormControl);
    console.debug(selectedFormControlName);
  }

  continueToStep3 = () => {
    return this.selectedCampaigns.length == 0;
  }

  onSubmitUserInviteForm() {
    console.debug('Submitted user invite form');
  }

  onSubmitAllAccessForm() {
    console.debug('Submitted user invite form');
  }
  // Dummy function for saving roles and products
  saveRolesAndProducts() {
    console.debug('Saving roles and products for the selected campaigns...');
  }
}
