import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss'
})
export class PageNotFoundComponent {
  isSpecificRoute = false;

  constructor(private router: Router) {
    this.router.events.subscribe(() => {
      this.checkRoute();
    });
  }

  checkRoute() {
    
    this.isSpecificRoute = this.router.url.includes('invite-new-user');
  }

  onActivate(component: any) {
    // Check the activated component type
    this.isSpecificRoute = component.constructor.name === 'UserCampaignInviteComponent';
  }

}
