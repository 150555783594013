import { Component, Inject, Input } from '@angular/core';
import { DomSanitizer, SafeHtml} from '@angular/platform-browser';
// import { cleanSVGForOutput } from '../../util/util';
import { NgFor, NgIf } from '@angular/common';
import NavModel from "../../model/NavModel"
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../config/app.config';
import { APP_CONFIG } from '../../config/config.token';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CampaignSwitcherComponent } from '../campaign-switcher/campaign-switcher.component';
import { SidebarModule } from 'primeng/sidebar';
import { MainModel } from '../../model/MainModel';
import App from '../../model/App';
import { NavService } from '../../services/ui/NavService';
import { UserPermissionsService } from '../../services/permissions/UserPermissionsService';

@Component({
  selector: 'app-app-nav',
  standalone: true,
  imports: [NgIf, CampaignSwitcherComponent, SidebarModule, NgFor],
  templateUrl: './app-bar.component.html',
  styleUrl: './app-bar.component.scss'
})
export class AppBarComponent {

  isSwitcherShwon:boolean = false;
  @Input() mode: "full"|"apps-only"|"account-only" = "full";

  constructor(
    private sanitizer: DomSanitizer,
    protected model: MainModel,
    private http: HttpClient,
    private msgService: MessageService,
    private navService: NavService,
    private permissionsAPI: UserPermissionsService,
    private router: Router,
    @Inject(APP_CONFIG) private config: AppConfig,
  ){
    // TODO: Check this onload from the session for last accessed application...
    // this.model.navigation.setCurrentNavModel(model.user);
  }

  onAppClick(app:App):void {
    console.debug("appclick");
    this.navService.setCurrentNavModel(app);
  }

  logout() {
    this.http.post(`${this.config.apiUrl}authentication/logout`, {}, {withCredentials: true}).subscribe({
      next: (data) => {
        this.msgService.add({ severity: 'success', summary: 'Logout', detail: 'User logged out successfully!' });
        setTimeout(() => {
          this.router.navigate(['login']);
        }, 1000);
      },
      error: (err) => {
        this.msgService.add({ severity: 'error', summary: 'Error', detail: 'Error while logging out user!' });
      }
    })
  }
}
