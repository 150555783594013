
<!-- Fly out content -->
<ng-container *ngIf="isDockable === true">
    <div class="selected-campaign-container">
        <p class="selected-campaign-title">
            <button class="p-button p-button-text neutral-60 flex align-items-center" (click)="toggle($event)">
                <span class="selected-campaign-text">{{model.selectedCampaign()?.name || "Select Campaign"}}</span>
            </button>
        </p>
    </div>

    <p-sidebar [(visible)]="isSidebarVisible" position="right" >
        <ng-template pTemplate="header">
            <span class="h3 m-0">Select Campaign</span>
        </ng-template>

        <div class="formgrid grid">
            <div class="field col-12 sm:col-6 md:col-4">
                <rc-input 
                    id="donorQuickfind" 
                    type="text" 
                    icon="search" 
                    [classes]="['input-quickfind']"
                    placeholder="Type to find campaign" 
                    [control]="filterValue"
                    /> 
            </div>
            <div class="field col-12 sm:col-6 md:col-8 flex align-items-center">
                <!-- TODO: [(ngModel)]="checked"  -->
                <p-checkbox 
                    [binary]="true"
                    inputId="inactiveCampaignsCheck"
                    label="Show Inactive Campaigns" />
            </div>
        </div>

        <recent-campaigns [campaigns]="model.lastAccessedCampaigns.slice(0,3)" />
        
        <p-card>
            <ul class="grid campaign-switcher-grid" >
                <li *ngFor="let item of filterResults()" class="col-12 sm:col-6 md:col-3">
                    <span (click)="selectCampaign(item)" [ngClass]="getClass(item)">
                        {{ item.name }}
                    </span>
                </li>
            </ul>
        </p-card>
    </p-sidebar>
</ng-container>

<!-- In page content -->
<ng-container *ngIf="isDockable === false">

    <h1>Select Campaign</h1>

    <div class="formgrid grid">
        <div class="field col-12 sm:col-6 xl:col-4">
            <rc-input 
                id="donorQuickfind" 
                type="text" 
                icon="search" 
                [classes]="['input-quickfind']" 
                placeholder="Type to find campaign" 
                [control]="filterValue"
                /> 
        </div>
        <div class="field col-12 sm:col-6 xl:col-8 flex align-items-center">
            <!-- TODO: [(ngModel)]="checked"  -->
            <p-checkbox 
                
                [binary]="true"
                inputId="inactiveCampaignsCheck"
                label="Show Inactive Campaigns" />
        </div>
    </div>

    <recent-campaigns [campaigns]="model.lastAccessedCampaigns.slice(0,3)" />

    <p-card>
        <ul class="grid campaign-switcher-grid">
            <li *ngFor="let item of filterResults()" class="col-12 sm:col-6 md:col-4 xl:col-3">
                <span (click)="selectCampaign(item)" [ngClass]="getClass(item)">
                    {{ item.name }}
                </span>
            </li>
        </ul>
    </p-card>

</ng-container>