import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, of } from 'rxjs';
import { APP_CONFIG } from '../../global/config/config.token';
import { AppConfig } from '../../global/config/app.config';

export interface Donation {
  campaignId: string;
  donorId: string;
  //donorName: string;
  //donorDetails: string;
  amount: number;
  date: string; // ISO format
  pledged: boolean;
  fundCodeId: string;
  contributionSourceId: string;
  contributionTypeId: string;
  checkNumber?: string;
  eventCodeId: string;
  fundraiserIds?: string[];
  fecLineNumberId: string;
  isMemo: boolean;
  fecDescription?: string;
  notes?: string;
  fecCustomNote?: string;
  parentChildDonation?: string;
  isMasterDonation: boolean;
  transferTypeId: string;
  parentDonationName?: string;
  parentDonationId?: string;
  parentDonationNumber?: string;
  pacLimitNotAffected: boolean
}

@Injectable({
  providedIn: 'root',
})
export class AddDonationService {
  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig) {
  }

  saveDonation(donationData: Donation): Observable<Donation> {
    const vm = {
      donorId: donationData.donorId,
      campaignId: donationData.campaignId,
      donationInformation: donationData
    };
    return this.http.post<Donation>(`${this.config.apiUrl}donation/add-donation`, vm, { withCredentials: true });
  }

  getDonations(): Observable<Donation[]> {
    return this.http.get<Donation[]>(`${this.config.apiUrl}donation`, { withCredentials: true });
  }

  updateDonation(id: string, donationData: Donation): Observable<Donation> {
    return this.http.put<Donation>(`${this.config.apiUrl}donation/${id}`, donationData, { withCredentials: true });
  }

  getAddDonationLookups(selectedCampaignId: number): Observable<any> {
    return this.http.get<any>(`${this.config.apiUrl}donation/add-donation-lookups?campaignId=${selectedCampaignId}`, { withCredentials: true });
  }

  getDonorInfo(donorId: number): Observable<any> {
    return this.http.get<any>(`${this.config.apiUrl}donation/donor-info?donorId=${donorId}`, { withCredentials: true });
  }

  deleteDonation(id: string): Observable<void> {
    return this.http.delete<void>(`${this.config.apiUrl}donation/${id}`, { withCredentials: true });
  }

  getParentDonorDonations(donorId: number): Observable<any> {
    return this.http.get<any>(`${this.config.apiUrl}donation/parent-donations?donorId=${donorId}`, { withCredentials: true });
  }

  searchFundCodes(query: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.config.apiUrl}donation/fund-codes`, {
      params: { search: query },
    }).pipe(
      catchError(() => of([]))
    );
  }

  searchFundraisers(query: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.config.apiUrl}donation/fundraisers`, {
      params: { search: query },
    }).pipe(
      catchError(() => of([]))
    );
  }
}
