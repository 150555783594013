<h1>Search {{dataType ? dataType.charAt(0).toUpperCase() + dataType.slice(1) : ""}} Page</h1>

<p>
    You will need to configure: 
</p>
<ul>
    <li>Which type of data will be searched & listed here</li>
    <li>What url each item will link to - e.g. /donor/id or /donations/id</li>
</ul>
<app-search 
    [pagingModel]="pagingModel"
    [showInDialog]="showInDialog"
    [columnData]="columnDataResult"
    (dataViewUpdate)="queryData($event)" 
    ngIf="pagingModel.data.length" 
    />
