import { Component, OnInit, effect } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { TableModule } from 'primeng/table';
import { TabViewModule } from "primeng/tabview";
import { DonorCardService } from "../../services/donor-card.service";
import { MainModel } from "../../../global/model/MainModel";
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from "@angular/forms";


@Component({
  selector: 'app-donor-card',
  templateUrl: './donor-card.component.html',
  styleUrls: ['./donor-card.component.scss'],
  standalone: true,
  imports: [FormsModule, TabViewModule, CardModule, ButtonModule, CommonModule, TableModule, CheckboxModule],
  providers: [DonorCardService]
})
export class DonorCardComponent implements OnInit {
  donorId: string;
  headerData: any;
  generalInfo: any;
  primaryAddress: any;
  phoneNumber: any;
  emailAddress: any;
  expandedRows = {};
  fundcodesHistory = [];
  donorLetters = [];
  donorCallsheetsLog = [];
  donorNotes = [];
  donorFlags = [];
  donorRelationships = [];
  donorSalutations: any = {};
  donorSuppression: any = {};

  tabIndex = 0;
  activeTab = 0;

  constructor(private route: ActivatedRoute,
    private mainModel: MainModel,
    private donorCardService: DonorCardService) {
    this.donorId = this.route.snapshot.paramMap.get('id') ?? '';

    effect(() => {
      this.loadGeneralInformation();
      this.loadFundcodesHistory();
      this.loadDonorLetters();
      this.loadDonorCallsheetsLog();
      this.loadDonorNotes();
      this.loadDonorFlags();
      this.loadDonorRelationships();
      this.loadDonorSalutations();
      this.loadDonorSuppression();
    });
  }

  switchTab(selectedTab: any) {
    this.activeTab = selectedTab.index;

    switch (this.activeTab) {
      case 0: {
        this.loadGeneralInformation();
        break;
      }
      case 1: {
        this.loadContactInformation();
        break;
      }
      case 2: {
        this.loadDonorLetters();
        break;
      }
    }
  }

  // general-info endpoint
  loadGeneralInformation(): void {
    if (this.donorId !== '') {
      this.donorCardService.getDonorCardGeneralInfo(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.generalInfo = data.generalInformation;
          this.headerData = data.donorCardHeader;
        },
        error: err => {
          console.error('Error fetching data from api:', err);
        },
        complete: () => console.debug("general-info service call complete")
      });
    }
  }

  loadContactInformation(): void {
    if (this.donorId !== '') {
      this.donorCardService.getDonorCardContactInfo(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.primaryAddress = data.addresses.filter((item: any) => item.isPrimaryString === "Yes")[0];
          this.phoneNumber = (data.phones.length > 0) ? data.phones.filter((i: any) => i.phoneTypeId === 1)[0].donorPhoneNumber : "";
          this.emailAddress = (data.emails.length > 0) ? data.emails.filter((i: any) => i.emailTypeId === 1)[0].email : "";
        },
        error: err => {
          console.error('Error fetching data from api:', err);
        },
        complete: () => console.debug("contact-info service call complete")
      });
    }
  }

  loadDonorLetters(): void {
    if (this.donorId !== '') {
      const c: any = this.mainModel.selectedCampaign();
      this.donorCardService.getDonorCardDonorLetters(parseInt(c?.campaignId), parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.donorLetters = data.selectedLetterCommunications;
        },
        error: err => {
          console.error('Error fetching data from api:', err);
        },
      });
    }
  }

  loadDonorCallsheetsLog(): void {
    if (this.donorId !== '') {
      const c: any = this.mainModel.selectedCampaign();
      this.donorCardService.getDonorCardCallsheetsLog(parseInt(c?.campaignId), parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.donorCallsheetsLog = data;
        },
        error: err => {
          console.error('Error fetching data from api:', err);
        },
      });
    }
  }

  loadDonorNotes(): void {
    if (this.donorId !== '') {
      this.donorCardService.getDonorNotes(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.donorNotes = data;
        },
        error: err => {
          console.error('Error fetching data from api:', err);
        },
      });
    }
  }

  loadDonorFlags(): void {
    if (this.donorId !== '') {
      this.donorCardService.getDonorFlags(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.donorFlags = data;
        },
        error: err => {
          console.error('Error fetching data from api:', err);
        },
      });
    }
  }

  loadDonorSalutations(): void {
    if (this.donorId !== '') {
      this.donorCardService.getDonorSalutations(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.donorSalutations = data;
        },
        error: err => {
          console.error('Error fetching data from api:', err);
        },
      });
    }
  }

  loadDonorSuppression(): void {
    if (this.donorId !== '') {
      this.donorCardService.getDonorSuppression(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.donorSuppression = data;
        },
        error: err => {
          console.error('Error fetching data from api:', err);
        },
      });
    }
  }

  loadDonorRelationships(): void {
    if (this.donorId !== '') {
      this.donorCardService.getDonorRelationships(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.donorRelationships = data;
        },
        error: err => {
          console.error('Error fetching data from api:', err);
        },
      });
    }
  }

  ngOnInit(): void {

  }

  getRemainingFlag(remaining: string) {
    if (parseFloat(remaining) < 0) {
      return 'danger';
    }
    return null;
  }

  loadFundcodesHistory(): void {
    const c: any = this.mainModel.selectedCampaign();
    this.donorCardService.getFundcodesHistory(parseInt(c?.campaignId), parseInt(this.donorId)).subscribe({
      next: data => {
        console.debug(data);
        this.fundcodesHistory = data;
      },
      error: err => {
        console.error('Error while fetching fundcodes history data from api: ', err);
      }
    })
  }

}
