import { CommonModule, Location, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import Campaign from '../../model/Campaign';    
import { PanelMenuModule } from 'primeng/panelmenu';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MainModel } from '../../model/MainModel';

@Component({
  selector: 'rc-main-nav',
  standalone: true,
  imports: [PanelMenuModule, CommonModule, PanelMenuModule, NgIf],
  templateUrl: './main-nav.component.html',
  styleUrl: './main-nav.component.scss'
})

export class MainNavComponent {
  @Input() 
  selectedCampaign:Campaign|null = null;
  currentUrl:string = "";
  jsonRep:string = "";

  constructor(
    private router:Router, 
    protected model:MainModel,
    protected location:Location
  ){
    // JMW TODO: set user navigation based on user data...
    this.currentUrl = router.url;
    router.events.subscribe((value:any) => {
        if(value instanceof NavigationStart){
          //console.debug("Changing url:", value.url);
          this.currentUrl = value.url;
        }
        if(value instanceof NavigationEnd){
          this.model.navigation.currentState = "closed";
          }
      });
  }

  onNgInit(){
    this.model.navigation.currentState = "closed";
    
  }


  /**
   * We just need at  abse level to know if the user has base access.
   * @param item 
   * @returns 
   */
// isAccessible(item:any){
//   const p:Permission = this.permissionsAPI.getAccessFromFlattenedArray(item.featureIds, item.pageIds);
//   return p > Permission.None;
//   //this.model.security.userHasAccessToFeature(item.featureId) || this.model.security.userHasAccessToPage(item.pageId);
// }



getNavVizState = (item:any) =>{
  if(item.route && (this.currentUrl === item.route || this.currentUrl.indexOf(item.route) >= 0))
  {
    item.isActive = true;
    return "active";
  } else {
    if(item.items?.length > 0){
      const matchingSubItems = item.items?.filter((itm:any) => {
        return (this.currentUrl === itm.route || this.currentUrl.indexOf(itm.route) > 0);
      });
      const isActiveSection = matchingSubItems.length > 0;
      item.isActive = true;
      if(isActiveSection)return "active";
    }
  }
  item.isActive = !true;
  return "not-active";
}

  outputIcon = (icon:any) => {
    return icon; 
  }
}

