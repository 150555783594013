
<div class="card">

    <div id="search-column-toggle" class="flex vertical">
        <p-multiSelect 
            [group]="true" 
            [options]="groupedOptions" 
            [(ngModel)]="selectedColumns"
            class="multi-select-columns"
            [virtualScrollItemSize]="43"
            optionLabel="displayColumn" 
            optionGroupLabel="label"
            optionGroupChildren="items"
            display="chip" 
            selectedItemsLabel="{0} columns selected"
            placeholder="Choose Columns" 
            [maxSelectedLabels]="100" 
            id="multi-select-columns" />
    </div>

    <!-- {{selectedColumns | json}} -->

    <div class="search-actions grid-nogutter py-3">
        <div class="button-group col-6">
            <button pButton pRipple (click)="onGenerateReport()" label = "Apply" class="p-button-secondary" id="button-clear-filters" icon="pi pi-filter">
            </button>
            <button pButton pRipple (click)="resetFilters()" label = "Clear" class="p-button-tertiary" id="button-clear-filters" icon="pi pi-filter-slash">
            </button>
        </div>
        <div class="button-group col-6 text-right">

          <button pButton
                  label="Add New Donor"
                  class="p-button-secondary"
                  (click)="onAddDonor()"></button>

          <button pButton pRipple (click)="onGenerateReport()" label="Compare" class="p-button-info" id="button-clear-filters" icon="pi pi-sync">
          </button>
          <p-button label="Export" icon="pi pi-download" (onClick)="menu.toggle($event)" id="button-export">
          </p-button>
        </div>
        <p-menu #menu [popup]="true" [model]="exportOptions"></p-menu>
    </div>

    <p-table 
        #dt 
        [scrollable]="true" 
        selectionMode="multiple"
        [(selection)]="selectedRows"
        [exportHeader]="'customExportHeader'"
        [reorderableColumns]="true" 
        [paginator]="true" 
        scrollHeight="flex" 
        [value]="rawData||[]"
        [columns]="selectedColumns" 
        [globalFilterFields]="filters"
        [rows]="pagingModel.maxResults"
        [sortField]="pagingModel.sortField" 
        [sortOrder]="pagingModel.sortAsc" 
        [rowsPerPageOptions]="[5, 10, 20, 100]"
        (onPage)="pageChange($event)" id="table-data">

        <ng-template pTemplate="caption">
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width:3rem">
                </th>
                <th style="width:3rem">
                </th>
                <th *ngFor="let col of columns" pReorderableColumn style="vertical-align: top;" nowrap
                    pSortableColumn={{col.columnName}} id="column-{{col.columnName}}">
                    <div class="flex">
                        <i class="fa-sharp fa-solid fa-grip-vertical right icon"></i>
                        <div class="full-width">{{col.displayColumn}}</div>
                        <p-sortIcon field={{col.columnName}} style="cursor: pointer;" id="sort-icon-{{col.columnName}}" />
                    </div>
                </th>
            </tr>
            <tr>
                <th style="width:6rem"></th>
                <th style="width:6rem">
                  <p-tableCheckbox id="checkbox-select-all"></p-tableCheckbox>
                </th>
                <th *ngFor="let col of columns">
                    <div *ngIf="col.displayColumnType && col.displayColumnType.columnType === 'StatesMultiSelect'">
                        <p-columnFilter field="State" matchMode="in" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect 
                                    [(ngModel)]="selectedStates" 
                                    [options]="states" 
                                    placeholder="Select States" 
                                    (onChange)="filter($event.value)"
                                    optionValue="value" 
                                    optionLabel="value"
                                    [maxSelectedLabels]="1"  
                                    appendTo="body" 
                                    [displaySelectedLabel]="true"
                                     emptyMessage = "No states found"  
                                     [selectedItemsLabel]="'{0} items selected'">
                                        <ng-template let-option pTemplate="item">
                                            <div class="inline-block vertical-align-middle">
                                                <span class="ml-1 mt-1">
                                                    {{ option.value }}
                                                </span>
                                            </div>
                                        </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                    <div *ngIf="col.displayColumnType && col.displayColumnType.columnType === 'FundCodesMultiSelect'">
                        <p-multiSelect [options]="fundCodes" optionLabel="value" optionValue="key" [(ngModel)]="selectedFundCodes" placeholder="Select FundCodes" [maxSelectedLabels]="1"  appendTo="body" [displaySelectedLabel]="true" emptyMessage = "No FundCodes found"  [selectedItemsLabel]="'{0} items selected'"></p-multiSelect>
                    </div>
                    <div *ngIf="col.displayColumnType && (col.displayColumnType.columnType === 'Text')">
                        <p-columnFilter type="text" field={{col.columnName}} placeholder="All" ariaLabel="Filter Name" id="filter-{{col.columnName}}"/>
                    </div>
                    <div *ngIf="col.displayColumnType && (col.displayColumnType.columnType === 'DropDown')">
                        <p-columnFilter type="text" field={{col.columnName}} placeholder="All" ariaLabel="Filter Name" id="filter-{{col.columnName}}"/>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
            <tr [pReorderableRow]="index" id="row-{{index}}">
                <td>
                    <span class="pi pi-bars" pReorderableRowHandle></span>
                </td>
                <td>
                  <p-tableCheckbox id="checkbox-row-{{index}}" [value]="rowData"></p-tableCheckbox>
                </td>
                <td class="text-center" *ngFor="let col of columns" nowrap>
                    <span *ngIf="col.columnName==='DonorID'"><a style="color:blue;">{{rowData[col.columnName]}}</a></span>
                    <span *ngIf="col.columnName!='DonorID'">{{rowData[col.columnName] || '-'}}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- element below should be conditional - only appears when table is empty, before user interacts with the filters -->
    <!-- <p>Adjust filters above and press Generate Report button to view your report.</p> -->
</div>

<div class="dialog-footer button-group text-right mt-3" *ngIf="showInDialog">
  <button pButton
          label="Cancel"
          class="p-button-secondary"
          (click)="onCancel()"></button>

  <button pButton
          label="Select Donor"
          [disabled]="!isSingleDonorSelected()"
          class="p-button-primary"
          (click)="onSelectDonor()"></button>
</div>

<p-toast></p-toast>

<br />
<br />
<br />
<br />
<ul>
    <li>Start: {{pagingModel.startRow}}</li>
    <li>Visible Rows: {{pagingModel.maxResults}}</li>
    <li>Number of Rows: {{pagingModel.count}}</li>
</ul>
