import { Component, Input, OnInit } from '@angular/core';
import { MainModel } from '../../model/MainModel';
import { NgFor, NgIf } from '@angular/common';
import Campaign from '../../model/Campaign';

@Component({
  selector: 'recent-campaigns',
  standalone: true,
  imports: [NgFor, NgIf],
  templateUrl: './recent-campaigns.component.html',
  styleUrl: './recent-campaigns.component.scss'
})
export class RecentCampaigns {

  @Input() campaigns:Array<any> = [];

  constructor(
    private model:MainModel
  ){
  }


  // setting this willtrigger call to get apps and features...
  selectCampaign(campaign:Campaign){
    this.model.selectedCampaign.set(campaign);
  }

}
